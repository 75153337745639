import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { useNavigate, useLocation } from 'react-router-dom';
import { CaretUp, Factory, Truck, Funnel, Basket, SquaresFour, Recycle, UsersThree, Leaf, ComputerTower, Storefront } from '@phosphor-icons/react';
import IndusNeeds from '../Assets/icones/indus-needs.svg?react';
import Role from '../Assets/icones/role.svg?react';
import Container from '../Assets/icones/container.svg?react';
import Bdd from '../Assets/icones/bdd.svg?react';
import { _defineModule } from '../Utils';

import * as Constants from '../Constants';

import { getSousMenuCdcFinancial } from '../navigation/Cdc/CdcMenu';
import { getSousMenuServiceSupplierOperation } from '../navigation/ServiceSupplier/ServiceSupplierMenu';
import { getSousMenuIndustrialOperation } from '../navigation/Industrial/IndustrialMenu';
import { getSousMenuSorterOperation } from '../navigation/Sorter/SorterMenu';
import { getSousMenuWasherOperation } from '../navigation/Washer/WasherMenu';
import { getSousMenuCollecteOperation } from '../navigation/Collecte/CollecteMenu';
import { getSousMenuRvmownerOperation } from '../navigation/Rvmowner/RvmownerMenu';
import { getSousMenuStoreownerOperation } from '../navigation/Storeowner/StoreownerMenu';

const Menu = ({ accessTab, type }) => {
    const location = useLocation();

    const navigate = useNavigate();
    const userToken = Cookies.get('userToken');

    const moduleChoosed = localStorage.getItem('module');

    const isSupplierService = location.pathname.startsWith('/servicesupplier');
    const isIndustrial = location.pathname.startsWith('/industrial');
    const isRvmOwner = location.pathname.startsWith('/rvmowner');
    const isStoreOwner = location.pathname.startsWith('/storeowner');
    const isError = location.pathname === '/error';

    const isSupplierServiceOrIndusAndOpe = (isSupplierService || isIndustrial || isRvmOwner) && moduleChoosed === Constants.MODULE_OPERATION;
    const isSupplierServiceOrIndusAndData = (isSupplierService || isIndustrial) && moduleChoosed === Constants.MODULE_DATA;
    const [selectedOnglet, setSelectedOnglet] = useState(isSupplierServiceOrIndusAndOpe ? 'homeOpe' : isSupplierServiceOrIndusAndData ? 'homeData' : null);
    const [selectedSousPartie, setSelectedSousPartie] = useState(null);
    const [isClicked, setIsClicked] = useState(true);
    const [selectedSousMenu, setSelectedSousMenu] = useState(isStoreOwner ? '/storeowner/operation/collector/list' : null);

    const checkDisplayMenu = () => {
        if (location.pathname.includes('/operation')) {
            return 1;
        } else {
            return 0;
        }
    };

    const handleOngletClick = (onglet) => {
        onglet === 'homeOpe' && isSupplierService && navigate('/servicesupplier/operation/home');
        onglet === 'homeOpe' && isIndustrial && navigate('/industrial/operation/home');
        onglet === 'homeData' && isIndustrial && navigate('/industrial/data/home');
        if (selectedOnglet === onglet) {
            setSelectedOnglet(null);
            setSelectedSousPartie(null);
        } else {
            setSelectedSousMenu(null);
            setSelectedOnglet(onglet);
            setSelectedSousPartie(null);
            setIsClicked(true);
        }
    };

    const handleSousPartieClick = (sousPartie) => {
        setSelectedSousPartie(selectedSousPartie === sousPartie ? null : sousPartie);
        setSelectedSousMenu(null);
    };

    const handleSousMenuClick = (sousMenuOption, bool) => {
        navigate(sousMenuOption.link);
        setSelectedSousMenu(selectedSousMenu === sousMenuOption.link ? null : sousMenuOption.link);
        if (bool === false) {
            setSelectedSousPartie(null);
        }
    };

    const getIconForOnglet = (onglet) => {
        switch (onglet) {
            case 'homeOpe':
            case 'homeData':
                return (
                    <SquaresFour
                        size={30}
                        color={selectedOnglet !== onglet ? Constants.$blanc : Constants.$vertBocoloco}
                    />
                );
            case 'indus_need':
                return <IndusNeeds />;
            case 'container':
                return <Container />;
            case 'bdd':
                return <Bdd />;
            case 'admin':
                return <Role />;
            case 'washer':
                return <Factory size={25} />;
            case 'collecte':
                return <Truck size={25} />;
            case 'indus':
                return (
                    <Basket
                        color={selectedOnglet !== onglet ? Constants.$blanc : Constants.$vertBocoloco}
                        size={25}
                    />
                );
            case 'sorter':
                return <Funnel size={25} />;
            case 'reuse':
                return (
                    <Recycle
                        size={25}
                        color={selectedOnglet !== onglet ? Constants.$blanc : Constants.$vertBocoloco}
                    />
                );
            case 'consumer':
                return (
                    <UsersThree
                        size={25}
                        color={selectedOnglet !== onglet ? Constants.$blanc : Constants.$vertBocoloco}
                    />
                );
            case 'impact':
                return (
                    <Leaf
                        size={25}
                        color={selectedOnglet !== onglet ? Constants.$blanc : Constants.$vertBocoloco}
                    />
                );
            case 'rvmowner':
                return (
                    <ComputerTower
                        size={25}
                        color={selectedOnglet !== onglet ? Constants.$blanc : Constants.$vertBocoloco}
                    />
                );
            case 'storeowner':
                return <Storefront size={25} />;

            default:
                return '';
        }
    };

    const getSousMenuOperation = (type, onglet) => {
        switch (type) {
            case Constants.ENTITY_TYPE_FS:
                return getSousMenuServiceSupplierOperation(onglet);
            case Constants.ENTITY_TYPE_INDUSTRIAL:
                return getSousMenuIndustrialOperation(onglet);
            case Constants.ENTITY_TYPE_SORTER:
                return getSousMenuSorterOperation(onglet);
            case Constants.ENTITY_TYPE_WASHER:
                return getSousMenuWasherOperation(onglet);
            case Constants.ENTITY_TYPE_OPERATOR:
                return getSousMenuCollecteOperation(onglet);
            case Constants.ENTITY_TYPE_RVMOWNER:
                return getSousMenuRvmownerOperation(onglet);
            case Constants.ENTITY_TYPE_STOREOWNER:
                return getSousMenuStoreownerOperation(onglet);
            case Constants.ENTITY_TYPE_CDC:
                return getSousMenuCdcFinancial(onglet);

            default:
                return [];
        }
    };

    const displayMenu = checkDisplayMenu();
    if (!displayMenu || isError) {
        return null;
    }

    return userToken ? (
        <section className="visible menu-lateral">
            <section className="nav">
                {accessTab.map((onglet, index) => (
                    <div
                        key={index}
                        style={{
                            background: ((!isIndustrial && !isSupplierService && !isRvmOwner) || selectedOnglet === onglet) && Constants.$blanc,
                            paddingBottom: '0.5rem',
                        }}>
                        <div
                            className={
                                (!isIndustrial && !isSupplierService && !isRvmOwner) || (isClicked && selectedOnglet === onglet)
                                    ? 'title title-selected'
                                    : 'title'
                            }
                            onClick={() => handleOngletClick(onglet)}>
                            <div>
                                <p
                                    className={
                                        (!isIndustrial && !isSupplierService && !isRvmOwner) || (isClicked && selectedOnglet === onglet)
                                            ? 'icone-title-selected'
                                            : 'icon-title'
                                    }>
                                    {getIconForOnglet(onglet)}
                                </p>
                            </div>
                            <div>
                                <p>{getSousMenuOperation(type, onglet)[0].title}</p>
                            </div>
                            <div>
                                <CaretUp
                                    style={{ color: selectedOnglet !== onglet ? Constants.$vertTresClair : Constants.$grisfonce }}
                                    size={15}
                                />
                            </div>
                        </div>
                        {((!isIndustrial && !isSupplierService && !isRvmOwner) || selectedOnglet === onglet) && (
                            <ul>
                                {getSousMenuOperation(type, onglet).map((sousPartie, sousPartieIndex) => (
                                    <div key={sousPartieIndex}>
                                        {Array.isArray(sousPartie.sousMenu) ? (
                                            <div>
                                                <div
                                                    className="sous-partie"
                                                    style={{
                                                        color: selectedSousPartie === sousPartie.sousPartie ? Constants.$vertBocoloco : '',
                                                        fontWeight: selectedSousPartie === sousPartie.sousPartie ? 600 : 400,
                                                    }}>
                                                    <div></div>
                                                    <div>
                                                        <p onClick={() => handleSousPartieClick(sousPartie.sousPartie)}>{sousPartie.sousPartie}</p>
                                                    </div>
                                                    <div></div>
                                                </div>

                                                <div className="sous-partie-text">
                                                    {selectedSousPartie === sousPartie.sousPartie && (
                                                        <ul>
                                                            {sousPartie.sousMenu.map((option, optionIndex) => (
                                                                <li key={optionIndex}>
                                                                    <div></div>
                                                                    <div>
                                                                        <span className="bullet-point">&#8226;</span>
                                                                        <p
                                                                            style={{
                                                                                color: selectedSousMenu === option.link && Constants.$vertBocoloco,
                                                                                fontWeight: selectedSousMenu === option.link ? 600 : 400,
                                                                            }}
                                                                            onClick={() => handleSousMenuClick(option, true)}>
                                                                            {option.name}
                                                                        </p>
                                                                    </div>
                                                                    <div></div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </div>
                                            </div>
                                        ) : (
                                            <li
                                                className="simple-menu"
                                                style={{
                                                    display: 'flex',
                                                    color: selectedSousMenu === sousPartie.link && Constants.$vertBocoloco,
                                                    fontWeight: selectedSousMenu === sousPartie.link && '600',
                                                }}>
                                                <div></div>
                                                <div>
                                                    <p onClick={() => handleSousMenuClick(sousPartie, false)}>{sousPartie.name}</p>
                                                </div>
                                                <div></div>
                                            </li>
                                        )}
                                    </div>
                                ))}
                            </ul>
                        )}
                    </div>
                ))}
            </section>
            <div
                style={{
                    width: '100%',
                    borderBottom: 'solid 1px',
                    borderBottomColor: Constants.$grisclair,
                    marginBottom: '0.5rem',
                }}></div>
            <div className="module">
                <p>MODULE : {_defineModule(moduleChoosed)}</p>
            </div>
        </section>
    ) : null;
};

export default Menu;
