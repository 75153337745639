import React, { useEffect, useState } from 'react';
import { getInfoUser } from '../../API/AccountApi';
import { _defineClientType } from '../../Utils';
import { createGitHubIssue } from '../../API/AccountApi';
import { serviceSupplierMenuOperation } from '../../navigation/ServiceSupplier/ServiceSupplierMenu';
import { getSousMenuServiceSupplierOperation } from '../../navigation/ServiceSupplier/ServiceSupplierMenu';
import { getSousMenuIndustrialOperation } from '../../navigation/Industrial/IndustrialMenu';
import { industrialMenuOperation } from '../../navigation/Industrial/IndustrialMenu';
import { washerMenuOperation } from '../../navigation/Washer/WasherMenu';
import { getSousMenuWasherOperation } from '../../navigation/Washer/WasherMenu';
import { sorterMenuOperation } from '../../navigation/Sorter/SorterMenu';
import { getSousMenuSorterOperation } from '../../navigation/Sorter/SorterMenu';
import { CollecteMenuOperation } from '../../navigation/Collecte/CollecteMenu';
import { getSousMenuCollecteOperation } from '../../navigation/Collecte/CollecteMenu';

export default function Support() {
    const [userInfos, setUserInfos] = useState([]);
    const [menu, setMenu] = useState([]);
    const [formData, setFormData] = useState({
        last_name: '',
        first_name: '',
        email: '',
        menu: '',
        cat: '',
        subject: '',
        message: '',
    });

    useEffect(() => {
        const _getInfosUser = async () => {
            const response = await getInfoUser();
            const result = _defineMenu(response[0].entity.type);
            setMenu(result);
            setUserInfos(response[0]);
        };

        _getInfosUser();
    }, []);

    const _defineMenu = (type) => {
        switch (type) {
            case 0:
                return serviceSupplierMenuOperation();
            case 1:
                return industrialMenuOperation();
            case 2:
                return sorterMenuOperation();
            case 3:
                return washerMenuOperation();
            case 4:
                return CollecteMenuOperation();
            default:
                return [];
        }
    };
    const getSousMenu = (type, onglet) => {
        switch (type) {
            case 0:
                return getSousMenuServiceSupplierOperation(onglet);
            case 1:
                return getSousMenuIndustrialOperation(onglet);
            case 2:
                return getSousMenuSorterOperation(onglet);
            case 3:
                return getSousMenuWasherOperation(onglet);
            case 4:
                return getSousMenuCollecteOperation(onglet);
            default:
                return [];
        }
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    // const handleSubmit = async (name, email, category, subject, message, tool, onglet) => {
    //     if (name && email && category && subject && message && tool) {
    //         setIsLoading(true);
    //         if ((tool === 'pilot' && !onglet) || (tool === 'hermes' && !onglet)) {
    //             alert('veuillez remplir tous les champs');
    //             setIsLoading(false);
    //         } else {
    //             const title = `${onglet ? `${onglet} :` : ''} ${subject}`;
    //             const finalMessage = `${name} - ${email} - ${message}`;
    //             const label = ['user', `${category}`];
    //             try {
    //                 const issue = await createGitHubIssue(title, finalMessage, label, tool);
    //                 if (issue.id) {
    //                     setIsMessageSent(true);
    //                     setIsLoading(false);
    //                     setIssuesTab((prevIssues) => [...prevIssues, issue]);
    //                 }
    //             } catch (error) {
    //                 alert('Une erreur est survenue. Veuillez réessayer plus tard.');
    //                 console.error("Erreur lors de la création de l'issue :", error);
    //                 setIsLoading(false);
    //             }
    //         }
    //     } else {
    //         alert('veuillez remplir tous les champs');
    //         setIsLoading(false);
    //     }
    // };

    const handleSubmit = async () => {
        const result = _defineClientType(userInfos.entity.type);
        if ((!formData.menu, !formData.cat, !formData.subject, !formData.message)) {
            alert('veuillez remplir tous les champs');
        } else {
            const title = ` ${result.name} - ${formData.menu} - ${formData.subject}`;
            const finalMessage = `${userInfos.first_name} ${userInfos.last_name} - ${userInfos.last_name} - ${formData.message}`;
            const label = ['user', `${formData.cat}`];
            try {
                const issue = await createGitHubIssue(title, finalMessage, label, 'gear');
                if (issue.id) {
                    console.log('okkkkkk');
                }
            } catch (error) {
                alert('Une erreur est survenue. Veuillez réessayer plus tard.');
                console.error("Erreur lors de la création de l'issue :", error);
            }
        }
    };

    return (
        <main
            className="support"
            style={{ height: '88vh' }}>
            <section className="big-section">
                <h2>Contacter le support</h2>
                <div className="form-container">
                    <section>
                        <div>
                            <label>Nom</label>
                            <input
                                disabled
                                type="text"
                                name="last_name"
                                value={userInfos.last_name || ''}
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <label>Prénom</label>
                            <input
                                disabled
                                type="text"
                                name="first_name"
                                value={userInfos.first_name || ''}
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <label>Email</label>
                            <input
                                disabled
                                type="text"
                                name="email"
                                value={userInfos.email || ''}
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <label>Menu:</label>
                            <select
                                name="menu"
                                onChange={handleChange}>
                                <option>Choisissez une option</option>
                                {menu.map((option, index) => (
                                    <option
                                        key={index}
                                        onChange={handleChange}
                                        value={option}>
                                        {getSousMenu(userInfos.entity.type, option)[0].title}
                                        {option.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label>Catégorie:</label>
                            <select
                                name="cat"
                                onChange={handleChange}>
                                <option value={''}>--</option>
                                <option value={'bug'}>Bug</option>
                                <option value={'enhancement'}>Amélioration</option>
                                <option value={'observation'}>Remarque</option>
                            </select>
                        </div>
                    </section>
                    <section>
                        <div>
                            <label>Sujet</label>
                            <input
                                required
                                type="text"
                                name="subject"
                                onChange={handleChange}
                            />
                        </div>

                        <div>
                            <label>Message</label>
                            <textarea
                                required
                                name="message"
                                cols="30"
                                rows="5"
                                onChange={handleChange}
                            />
                        </div>
                    </section>
                </div>
                <div className="button-confirm">
                    <button
                        className="button-actif"
                        onClick={handleSubmit}>
                        Envoyer
                    </button>
                </div>
            </section>
        </main>
    );
}
