import React, { useState, useRef } from 'react';
import { useEffect } from 'react';
import { getStoreByStoreownerId, createPickUpRequest, getEventDetailsByStoreId } from '../../../API/StoreApi';
import { getStoreBoxesByStoreId } from '../../../API/StoreBoxesApi';
import { getDepotById } from '../../../API/DepotApi';
import { getOperators } from '../../../API/OperatorApi';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { format } from 'date-fns';
import { Jwt } from '../../../Jwt';
import { SortAscending, SortDescending } from '@phosphor-icons/react';
import { _defineCollecteStatus, _defineBoxesStoreState, _defineStoreEventType } from '../../../Utils/';
import * as Constants from '../../../Constants';

export default function StoreOwnerDelivery() {
    const userContext = new Jwt();
    const [storeData, setStoreData] = useState([]);
    const [depotData, setDepotData] = useState([]);
    const [storeEventData, setStoreEventData] = useState([]);
    const [showCalendar, setShowCalendar] = useState(false);
    const [filter, SetFilter] = useState('asc');
    const [storeBoxes, setStoreBoxes] = useState([]);
    const [boxesCollecte, setBoxesCollecte] = useState([]);
    const [collecteInfos, setCollecteInfos] = useState({ time: null, storeId: null, sorterId: null, operatorId: null });
    const [operatorList, setOperatorList] = useState([]);
    const dateActuelle = new Date();

    const dialogRef = useRef(null);

    useEffect(() => {
        const fetchStoreStoreEvents = async () => {
            const storeRawData = await getStoreByStoreownerId(userContext.entityId);
            setStoreData(storeRawData[0]);

            const depotRawData = await getDepotById(storeRawData[0].depot_id);
            setDepotData(depotRawData[0]);

            const operatorsRawData = await getOperators();
            setOperatorList(operatorsRawData);

            const result = await getEventDetailsByStoreId(storeRawData[0].id);

            const resultFiltered = result.filter(
                (item) =>
                    (item.storeEvent.type === Constants.STOREEVENT_TYPE_COLLECTE && item.storeEvent.status !== Constants.EVENT_RECEIVED) ||
                    (item.storeEvent.type === Constants.STOREEVENT_TYPE_DELIVERY && item.storeEvent.status !== Constants.EVENT_DONE),
            );

            // console.log('result', result);

            // const groupByContainerId = result.reduce((acc, item) => {
            //     const containerName = item.container.name;

            //     if (!acc[containerName]) {
            //         acc[containerName] = [];
            //     }

            //     acc[containerName].push(item);

            //     return acc;
            // }, {});

            // const formattedData = Object.keys(groupByContainerId).map((containerName) => ({
            //     containerName,
            //     date: groupByContainerId[containerName][0].storeEvent.time,
            //     storeEventDetails: groupByContainerId[containerName],
            // }));

            setStoreEventData(resultFiltered);
        };

        fetchStoreStoreEvents();
    }, [
        userContext.entityId,
    ]);

    const sortData = (data, setData) => {
        const sortedData = [...data].sort((a, b) => (filter === 'asc' ? a.date.localeCompare(b.date) : b.date.localeCompare(a.date)));
        setData(sortedData);
    };

    const customButtons = {
        today: {
            text: "Aujourd'hui",
        },
    };

    const handleModalOpen = async () => {
        const hostname = window.location.hostname;

        // https://github.com/Bocolocoo/gear/issues/572
        let operator;
        if (hostname === 'localhost') {
            console.log('localhost');
            operator = 81;
        } else if (hostname === 'gear.demo.bocotech.fr') {
            console.log('demo');
            operator = 81;
        } else if (hostname === 'gear.stag.bocotech.fr') {
            console.log('stag');
            operator = 19;
        } else if (hostname === 'gear.bocotech.fr') {
            console.log('prod');
            operator = 2;
        } else {
            console.log('default');
            operator = 81;
        }

        setCollecteInfos({ ...collecteInfos, storeId: storeData.id, sorterId: depotData.entity_sorter_id, operatorId: operator });

        const storeBoxesData = await getStoreBoxesByStoreId(storeData.id);
        const boxesFilled = storeBoxesData.filter((item) => item.empty === false);
        const groupedData = boxesFilled.reduce((acc, item) => {
            const name = item.container.name;

            if (!acc[name]) {
                acc[name] = [];
            }

            acc[name].push(item);

            return acc;
        }, {});

        const result = Object.keys(groupedData).map((name) => ({
            name,
            items: groupedData[name],
        }));

        setStoreBoxes(result);

        if (dialogRef.current) {
            dialogRef.current.showModal();
        }
    };
    const handleModalClose = () => {
        if (dialogRef.current) {
            dialogRef.current.close();
        }
    };

    const sendPickUpRequest = async () => {
        const data = {
            boxesCollecte,
            collecteInfos,
        };
        const request = await createPickUpRequest(data);
        if (request.message === 'ok') {
            alert('La collecte a bien été effectuée');
            handleModalClose();
            window.location.reload();
        } else {
            alert('Une erreur est survenue');
        }

        console.log('request', request);
    };

    useEffect(() => {
        const initialFormData = storeBoxes.map((item) => ({
            containerName: item.name,
            containerId: item.items[0].containerId,
            qty: 0,
        }));
        setBoxesCollecte(initialFormData);
    }, [storeBoxes]);

    const handleInputChange = (index, value) => {
        const newFormData = [...boxesCollecte];
        newFormData[index] = { ...newFormData[index], qty: value };

        setBoxesCollecte(newFormData);
    };

    console.log(boxesCollecte);

    return (
        <main>
            <section className="big-section">
                <div className="top top-history">
                    <h2>Liste des collectes</h2>
                    <div style={{ display: 'flex', gap: '1rem' }}>
                        <div
                            className="sort"
                            style={{ height: '2.75rem' }}>
                            <p
                                onClick={() => {
                                    setShowCalendar(false);
                                }}
                                className={!showCalendar ? 'selected' : 'classic'}>
                                Liste
                            </p>
                            <p
                                onClick={() => {
                                    setShowCalendar(true);
                                }}
                                className={showCalendar ? 'selected' : 'classic'}>
                                Calendrier
                            </p>
                        </div>

                        <button
                            // disabled={boxesCollecte.length === 0}
                            // style={{ opacity: boxesCollecte.length === 0 ? 0.5 : 1 }}
                            onClick={handleModalOpen}
                            className="button-actif">
                            DEMANDER UNE COLLECTE
                        </button>
                    </div>
                </div>
                {!showCalendar ? (
                    <div className="tab-item">
                        <div
                            className="pre-filled-fields-1-several-item"
                            style={{ fontWeight: '600', width: '100%' }}>
                            <p>Type</p>
                            <p>Type de caisse</p>
                            <p style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>Date estimée</span>
                                <span
                                    style={{ paddingRight: '0.93rem', cursor: 'pointer' }}
                                    onClick={() => {
                                        SetFilter(filter === 'asc' ? 'desc' : 'asc');
                                        sortData(storeEventData, setStoreEventData);
                                    }}>
                                    {filter === 'asc' ? <SortAscending size={15} /> : <SortDescending size={15} />}
                                </span>
                            </p>
                            <p>Qté caisses</p>
                            <p>Etat des caises</p>
                            <p>Statut</p>
                            <p>+/- caisses vides</p>
                        </div>
                        {storeEventData.length
                            ? storeEventData.map((item, index) => {
                                  return (
                                      <div
                                          style={{ display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'space-between' }}
                                          key={index}>
                                          <div
                                              style={{ width: '100%' }}
                                              className="pre-filled-fields-2-several-item">
                                              <p>{_defineStoreEventType(item.storeEvent.type)}</p>
                                              <p>{item.container.name}</p>
                                              <p>{format(item.storeEvent.time, 'yyyy-MM-dd')}</p>
                                              <p>{item.qty}</p>
                                              <p>{_defineBoxesStoreState(item.empty)}</p>
                                              <p
                                                  style={{
                                                      backgroundColor: _defineCollecteStatus(item.storeEvent.status).color,
                                                      color: Constants.$blanc,
                                                  }}>
                                                  {_defineCollecteStatus(item.storeEvent.status).name}
                                              </p>
                                              <p></p>
                                          </div>
                                      </div>
                                  );
                              })
                            : null}
                    </div>
                ) : (
                    <FullCalendar
                        events={storeEventData.map((item, index) => {
                            return {
                                index: index,
                                title: `demande`,
                                date: item.date,
                                eventData: item,
                            };
                        })}
                        initialView="dayGridMonth"
                        plugins={[dayGridPlugin]}
                        locale="fr"
                        customButtons={customButtons}
                    />
                )}
            </section>
            <dialog
                ref={dialogRef}
                className="dialog-collector">
                <div className="title">
                    <p>Informations collecte</p>
                    <button
                        className="button-negatif"
                        onClick={handleModalClose}>
                        X
                    </button>
                </div>

                {boxesCollecte.map((item, index) => {
                    return (
                        <div
                            key={index}
                            className="collector-infos">
                            <div className="depart-arrival-container">
                                <div
                                    className="departure"
                                    style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                    <h4>Contenant</h4>
                                    <p>{item.containerName}</p>
                                </div>
                                <div>
                                    <h4>Quantité</h4>
                                    <select
                                        value={item.qty}
                                        onChange={(e) => {
                                            handleInputChange(index, e.target.value);
                                        }}>
                                        <option value="">--</option>
                                        {Array.from({ length: storeBoxes[index].items.length }, (_, i) => i + 1).map((num) => (
                                            <option
                                                key={num}
                                                value={num}>
                                                {num}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    );
                })}
                {/* <div
                    className="depart-arrival-container"
                    style={{ display: 'flex', marginTop: '1rem' }}>
                    <div
                        className="departure"
                        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '50%' }}>
                        <h4>Opérateur</h4>
                    </div>
                    <select
                        onChange={(e) => {
                            setCollecteInfos({ ...collecteInfos, operatorId: e.target.value });
                        }}>
                        <option value="">--</option>
                        {operatorList.map((operator) => (
                            <option
                                key={operator.id}
                                value={operator.id}>
                                {operator.name}
                            </option>
                        ))}
                    </select>
                </div> */}
                <div
                    className="depart-arrival-container"
                    style={{ display: 'flex', marginTop: '1rem' }}>
                    <div
                        className="departure"
                        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '50%' }}>
                        <h4>Date collecte :</h4>
                    </div>
                    <div style={{ width: '50%' }}>
                        <input
                            style={{ width: '67%' }}
                            min={format(dateActuelle, 'yyyy-MM-dd')}
                            type="date"
                            onChange={(e) => {
                                setCollecteInfos({ ...collecteInfos, time: e.target.value });
                            }}
                        />
                    </div>
                </div>
                <div className="collector-validate">
                    <button
                        className="button-actif"
                        onClick={() => {
                            sendPickUpRequest();
                        }}>
                        VALIDER LA PROGRAMMATION
                    </button>
                </div>
            </dialog>
        </main>
    );
}
