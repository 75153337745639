import { useState, useEffect, useRef, useCallback } from 'react';
import { getStoreByRvmownerId } from '../../../../API/StoreApi';
import { getStoreByRvmownerIdCheck } from '../../../../API/CheckApi/CheckStoreApi';
import { _defineStatus, _defineCount, useClickOutside } from '../../../../Utils';
import { Link, useNavigate } from 'react-router-dom';
import { Jwt } from '../../../../Jwt';
import * as Constant from '../../../../Constants';
import * as Sentry from '@sentry/react';
export default function Store() {
    const [filterSelected, setFilterSelected] = useState('desc');

    const navigate = useNavigate();
    const userContext = new Jwt();
    const [storeList, setStoreList] = useState([]);
    const [storesNames, setStoreNames] = useState([]);
    const [showOptions, setShowOptions] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const dropdownRef = useRef(null);

    const handleClickOutside = () => {
        setShowOptions(false);
    };
    useClickOutside(dropdownRef, handleClickOutside);

    const fetchStoresByRvmowner = useCallback(async () => {
        try {
            const result = await getStoreByRvmownerId(userContext.entityId);
            const resultCheck = getStoreByRvmownerIdCheck(result);
            if (result.length && resultCheck !== true) {
                console.error(resultCheck);
                Sentry.captureException(resultCheck);
                navigate('/error');
                return;
            }

            const sortedData = result.sort((a, b) => a.name.localeCompare(b.name));
            setFilterSelected('asc');
            setStoreList(sortedData);
        } catch (error) {
            console.error('Erreur lors de la récupération des stores', error);
        }
    }, [userContext.entityId, navigate]);

    useEffect(() => {
        const fetchStoreNames = async () => {
            try {
                if (searchTerm.length > 2) {
                    //                    var result = await getDepotsByName(searchTerm);
                    const result = [];
                    if (result.length) {
                        setStoreList(result);
                        setStoreNames(result);
                        setErrorMessage('');
                    } else {
                        setStoreList([]);
                        setStoreNames([]);
                        setErrorMessage('Aucun résultat');
                    }
                } else if (searchTerm.length === 0) {
                    fetchStoresByRvmowner();
                    setErrorMessage('');
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des depots', error);
            }
        };

        fetchStoreNames();
    }, [searchTerm, fetchStoresByRvmowner]);

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
        setShowOptions(true);
    };

    const handleSelectOption = (option) => {
        setSearchTerm(option.name);
        setShowOptions(false);
        console.log(option);
        navigate('/rvmowner/operation/store/sheet', { state: { id: option.id } });
    };

    const sortDataAsc = () => {
        const sortedData = [...storeList].sort((a, b) => a.name.localeCompare(b.name));
        setFilterSelected('asc');
        setStoreList(sortedData);
    };

    const sortDataDesc = () => {
        const sortedData = [...storeList].sort((a, b) => b.name.localeCompare(a.name));
        setFilterSelected('desc');
        setStoreList(sortedData);
    };

    return (
        <main>
            <section className="big-section ">
                <h2>Les Magasins ({storeList.length})</h2>
                <div className="top">
                    <div
                        ref={dropdownRef}
                        style={{ position: 'relative' }}
                        className="scrolling-search-menu">
                        <input
                            style={{ outline: 'none', borderBottomLeftRadius: showOptions && '0px', borderBottomRightRadius: showOptions && '0px' }}
                            type="text"
                            value={searchTerm}
                            onChange={handleInputChange}
                            placeholder="Rechercher un magasin"
                        />

                        {showOptions && (
                            <div
                                style={{ position: 'absolute', top: '100%', left: 0, zIndex: 1 }}
                                className="test-option">
                                <div>
                                    <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
                                        {storesNames.length
                                            ? storesNames.map(
                                                  (option, index) =>
                                                      option.name.toLowerCase().includes(searchTerm) && (
                                                          <li
                                                              key={index}
                                                              onClick={() => handleSelectOption(option)}>
                                                              {option.name}
                                                          </li>
                                                      ),
                                              )
                                            : null}
                                    </ul>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="top-button-sort">
                        <div className="filter">
                            <p className="sort">Trier par</p>
                            <div>
                                <p
                                    className={filterSelected === 'asc' ? 'selected' : 'classic'}
                                    onClick={sortDataAsc}>
                                    A &gt; Z
                                </p>
                                <p
                                    className={filterSelected === 'desc' ? 'selected' : 'classic'}
                                    onClick={sortDataDesc}>
                                    Z &gt; A
                                </p>
                            </div>
                        </div>
                        <Link
                            to={'/rvmowner/operation/store/add'}
                            className="button-actif">
                            AJOUTER UN MAGASIN
                        </Link>
                    </div>
                </div>
                {errorMessage && (
                    <div style={{ width: '100%', padding: '0.5rem', textAlign: 'center', color: Constant.$rouge, marginBottom: '1.25rem' }}>
                        <p>{errorMessage}</p>
                    </div>
                )}
                <div className="tab-item">
                    <div
                        className="pre-filled-fields-1-several-item"
                        style={{ fontWeight: '600' }}>
                        <p>Nom</p>
                        <p>Ville</p>
                        <p>Code Postal</p>
                        <p>Centre de collecte</p>
                        <p>Statut</p>
                        <p>Nb RVM</p>
                    </div>
                    {storeList.length ? (
                        storeList.map((item, index) => {
                            return (
                                <Link
                                    key={index}
                                    to={'/rvmowner/operation/store/sheet'}
                                    state={{ id: item.id }}
                                    className="pre-filled-fields-2-several-item">
                                    <p>{item.name}</p>
                                    <p>{item.city}</p>
                                    <p>{item.zipcode}</p>
                                    <p>{item.depot_name}</p>
                                    <p
                                        style={{
                                            backgroundColor: _defineStatus(item.status).color,
                                            color: Constant.$blanc,
                                        }}>
                                        {_defineStatus(item.status).name}
                                    </p>
                                    <p
                                        style={{
                                            backgroundColor: _defineCount(item.count_rvm).backgroundcolor,
                                            color: _defineCount(item.count_rvm).color,
                                            fontWeight: _defineCount(item.count_rvm).fontweight,
                                            borderRadius: '0.6rem',
                                            textAlign: 'center',
                                        }}>
                                        {item.count_rvm}
                                    </p>
                                </Link>
                            );
                        })
                    ) : (
                        <div className="no-data-graph ">Aucun magasin enregistré.</div>
                    )}
                </div>
            </section>
        </main>
    );
}
