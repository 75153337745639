import { getGearAPIApiEndpoint } from './endpoints';
import * as Sentry from '@sentry/react';
import { defaultHeaders } from './headers';

const GEAR_API_BASE_URL = getGearAPIApiEndpoint();

// COLLECTOR
const BOCOLOCO_GET_COLLECTOR = '/collector/get';
const BOCOLOCO_GET_COLLECTORBYID = '/collector/getbyid';
const BOCOLOCO_GET_COLLECTORBYRVMOWNER = '/collector/getbyrvmowner';
const BOCOLOCO_ADD_COLLECTOR = '/collector/add';
const BOCOLOCO_UPDATE_COLLECTOR = '/collector/update';
const BOCOLOCO_GET_COLLECTOR_BYDEPOTID = '/collector/getbydepotid';
const BOCOLOCO_GET_COLLECTOR_BYSTOREID = '/collector/getbystoreid';
const BOCOLOCO_DELETE_COLLECTOR = '/collector/delete';
// const BOCOLOCO_CREATE_PICKUPDEMAND = '/collector/event/request';
// const BOCOLOCO_CREATE_PICKUPACCEPT = '/collector/event/accept';
// const BOCOLOCO_CREATE_PICKUPDONE = '/collector/event/done';
// const BOCOLOCO_GET_EVENTSBYCOLLECTORID = '/collector/event/getbycollector';
// const BOCOLOCO_GET_EVENTSBYSTOREID = '/collector/event/getbystore';
// const BOCOLOCO_GET_COLLECTOR_BYOPERATORIDANDDATE = '/collector/event/getbyoperatoridanddate';
// const BOCOLOCO_GET_COLLECTOREVENTSBYOPERATOR = '/collector/event/getbyoperator';
// const BOCOLOCO_GET_COLLECTOREVENTSBYSORTER = '/collector/event/getbysorter';
// const BOCOLOCO_CREATE_PICKUPRECEIVED = '/collector/event/received';
// const BOCOLOCO_GET_COLLECTOR_LAST_COLLECTOREVENTS_STATUS = '/collector/getlastcollectoreventstatus';
// const BOCOLOCO_GET_COLLECTOREVENTS_BYSUIVIID = '/collector/event/getbysuiviId';
const BOCOLOCO_RESET_FILLINGRATE = '/collector/resetfillingrate';
const BOCOLOCO_GET_COLLECTORBYDATES = '/collector/getbydates';
const BOCOLOCO_GET_COLLECTOR_BYSTOREOWNERID = '/collector/getbystoreownerid';
const BOCOLOCO_GET_COLLECTORBYDISTRIBUTOR = '/collector/getbydistributor';
const BOCOLOCO_GET_COLLECTORBYSTOREOWNER = '/collector/getbystoreowner';

export function getCollectors() {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_COLLECTOR;
    const url = encodeURI(url_base);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => response.json())
        .catch((error) => {
            throw error;
        });
}

export function getCollectorsByDates(startDate, endDate) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_COLLECTORBYDATES;
    var url_args = '?startDate=' + startDate + '&endDate=' + endDate;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getCollectorById(id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_COLLECTORBYID;
    var url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getCollectorsByRvmowner(rvmowner_id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_COLLECTORBYRVMOWNER;
    var url_args = '?id=' + rvmowner_id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getCollectorsByDistributor(distributor_id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_COLLECTORBYDISTRIBUTOR;
    var url_args = '?distributor_id=' + distributor_id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getCollectorsByStoreOwner(storeowner_id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_COLLECTORBYSTOREOWNER;
    var url_args = '?storeowner_id=' + storeowner_id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function addCollector(formData) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_ADD_COLLECTOR;
    const url = encodeURI(url_base);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(formData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR ADD COLLECTOR');
            console.log(error);
            throw error;
        });
}

export function updateCollector(id, updatedData) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_UPDATE_COLLECTOR;
    const url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(updatedData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR UPDATE COLLECTOR');
            console.log(error);
            throw error;
        });
}

export function getCollectorsByDepotId(depot_id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_COLLECTOR_BYDEPOTID;
    var url_args = '?depot_id=' + depot_id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getCollectorsByStoreId(store_id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_COLLECTOR_BYSTOREID;
    var url_args = '?store_id=' + store_id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getCollectorsByStoreOwnerId(storeOwnerId) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_COLLECTOR_BYSTOREOWNERID;
    var url_args = '?storeOwnerId=' + storeOwnerId;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function deleteCollector(id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_DELETE_COLLECTOR;
    var url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);

    return fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            throw error;
        });
}

// export function createPickUpDemand(collectorIds, sorter_id, operator_id, pickUpRequestTime) {
//     const url_base = GEAR_API_BASE_URL + BOCOLOCO_CREATE_PICKUPDEMAND;
//     var url_args = '?sorterId=' + sorter_id + '&operatorId=' + operator_id + '&pickUpRequestTime=' + pickUpRequestTime;
//     const url = encodeURI(url_base + url_args);
//     return fetch(url, {
//         method: 'PUT',
//         body: JSON.stringify(collectorIds),
//         headers: {
//             'Content-Type': 'application/json',
//             Accept: 'application/json',
//             ...defaultHeaders,
//         },
//     })
//         .then((response) => response.json())
//         .catch((error) => {
//             console.log('ERROR DEMAND PICK UP');
//             console.log(error);
//             throw error;
//         });
// }

// export function collectorPickUpAccept(collecteData, pickUpAcceptTime) {
//     const url_base = GEAR_API_BASE_URL + BOCOLOCO_CREATE_PICKUPACCEPT;
//     var url_args = '?time=' + pickUpAcceptTime;
//     const url = encodeURI(url_base + url_args);
//     return fetch(url, {
//         method: 'PUT',
//         body: JSON.stringify(collecteData),
//         headers: {
//             'Content-Type': 'application/json',
//             Accept: 'application/json',
//             ...defaultHeaders,
//         },
//     })
//         .then((response) => response.json())
//         .catch((error) => {
//             console.log('ERROR ACCEPT PICK UP');
//             console.log(error);
//             throw error;
//         });
// }

// export function collectorPickUpDone(collecteData, pickUpDoneTime) {
//     const url_base = GEAR_API_BASE_URL + BOCOLOCO_CREATE_PICKUPDONE;
//     var url_args = '?time=' + pickUpDoneTime;
//     const url = encodeURI(url_base + url_args);
//     return fetch(url, {
//         method: 'PUT',
//         body: JSON.stringify(collecteData),
//         headers: {
//             'Content-Type': 'application/json',
//             Accept: 'application/json',
//             ...defaultHeaders,
//         },
//     })
//         .then((response) => response.json())
//         .catch((error) => {
//             console.log('ERROR DONE PICK UP');
//             console.log(error);
//             throw error;
//         });
// }

// export function getEventsByCollectorId(id) {
//     const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_EVENTSBYCOLLECTORID;
//     var url_args = '?id=' + id;
//     const url = encodeURI(url_base + url_args);
//     return fetch(url, { headers: defaultHeaders })
//         .then((response) => {
//             if (response.ok) {
//                 return response.json();
//             } else {
//                 Sentry.captureException({ url, response });
//                 return null;
//             }
//         })
//         .catch((error) => {
//             throw error;
//         });
// }

// export function getEventsByStoreId(id) {
//     const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_EVENTSBYSTOREID;
//     var url_args = '?id=' + id;
//     const url = encodeURI(url_base + url_args);
//     return fetch(url, { headers: defaultHeaders })
//         .then((response) => response.json())
//         .catch((error) => {
//             throw error;
//         });
// }

// export function getCollectorEventsByOperatorIdAndDate(operator_id, date) {
//     const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_COLLECTOR_BYOPERATORIDANDDATE;
//     var url_args = '?operator_id=' + operator_id + '&date=' + date;
//     const url = encodeURI(url_base + url_args);
//     return fetch(url, { headers: defaultHeaders })
//         .then((response) => {
//             if (response.ok) {
//                 return response.json();
//             } else {
//                 Sentry.captureException({ url, response });
//                 return null;
//             }
//         })
//         .catch((error) => {
//             throw error;
//         });
// }

// export function getCollectorEventsByOperator(id) {
//     const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_COLLECTOREVENTSBYOPERATOR;
//     var url_args = '?id=' + id;
//     const url = encodeURI(url_base + url_args);
//     return fetch(url, { headers: defaultHeaders })
//         .then((response) => {
//             if (response.ok) {
//                 return response.json();
//             } else {
//                 Sentry.captureException({ url, response });
//                 return null;
//             }
//         })
//         .catch((error) => {
//             throw error;
//         });
// }

// export function getCollectorEventsBySorter(id) {
//     const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_COLLECTOREVENTSBYSORTER;
//     var url_args = '?id=' + id;
//     const url = encodeURI(url_base + url_args);
//     return fetch(url, { headers: defaultHeaders })
//         .then((response) => {
//             if (response.ok) {
//                 return response.json();
//             } else {
//                 Sentry.captureException({ url, response });
//                 return null;
//             }
//         })
//         .catch((error) => {
//             throw error;
//         });
// }

// export function collectorPickUpRecived(collecteData, pickUpDoneTime) {
//     const url_base = GEAR_API_BASE_URL + BOCOLOCO_CREATE_PICKUPRECEIVED;
//     var url_args = '?time=' + pickUpDoneTime;
//     const url = encodeURI(url_base + url_args);
//     return fetch(url, {
//         method: 'PUT',
//         body: JSON.stringify(collecteData),
//         headers: {
//             'Content-Type': 'application/json',
//             Accept: 'application/json',
//             ...defaultHeaders,
//         },
//     })
//         .then((response) => response.json())
//         .catch((error) => {
//             console.log('ERROR DONE PICK UP');
//             console.log(error);
//             throw error;
//         });
// }

// export function getCollectorsLastCollectorEventsStatus() {
//     const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_COLLECTOR_LAST_COLLECTOREVENTS_STATUS;
//     const url = encodeURI(url_base);
//     return fetch(url, { headers: defaultHeaders })
//         .then((response) => {
//             if (response.ok) {
//                 return response.json();
//             } else {
//                 Sentry.captureException({ url, response });
//                 return null;
//             }
//         })
//         .catch((error) => {
//             throw error;
//         });
// }

// export function getCollectorEventsBySuivId(suiviId) {
//     const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_COLLECTOREVENTS_BYSUIVIID;
//     var url_args = '?suiviId=' + suiviId;
//     const url = encodeURI(url_base + url_args);
//     return fetch(url, { headers: defaultHeaders })
//         .then((response) => {
//             if (response.ok) {
//                 return response.json();
//             } else {
//                 Sentry.captureException({ url, response });
//                 return null;
//             }
//         })
//         .catch((error) => {
//             throw error;
//         });
// }

export function collectorResetFillingRate(collector_id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_RESET_FILLINGRATE;
    var url_args = '?id=' + collector_id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR RESET FILLING RATE');
            console.log(error);
            throw error;
        });
}
