import { useState, useEffect, useRef, useCallback } from 'react';
import { getCollectorsByStoreOwnerId } from '../../../API/CollectorApi';
// import {getEventsByCollectorId} from '../../../API/CollectorApi';
import { _defineStatus, _defineCollecteurStatus, _defineCollectorType, useClickOutside } from '../../../Utils';
import { useNavigate } from 'react-router-dom';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
// import { format, parseISO } from 'date-fns';
import { Jwt } from '../../../Jwt';
import * as Constant from '../../../Constants';
import SearchComponent from '../../../Components/searchComponent';

export default function Collector() {
    const navigate = useNavigate();
    const userContext = new Jwt();
    const [filterSelected, setFilterSelected] = useState('asc');
    const [AllCollectorList, setAllCollectorList] = useState([]);
    const [originalCollectorList, setOriginalCollectorList] = useState([]);
    const [advancedSearch, setAdvancedSearch] = useState(false);
    const [typeTab, setTypeTab] = useState([]);
    const [selectedType, setSelectedType] = useState('');
    const [statusTab, setStatusTab] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState('');
    const [collectorNames, setCollectorNames] = useState([]);
    const [showOptions, setShowOptions] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    // const [data, setData] = useState([]);

    const dropdownRef = useRef(null);

    const handleClickOutside = () => {
        setShowOptions(false);
    };
    useClickOutside(dropdownRef, handleClickOutside);

    useEffect(() => {
        const fetchAllCollectors = async () => {
            try {
                const storeRawData = await getCollectorsByStoreOwnerId(userContext.entityId);
                const sortedData = storeRawData.sort((a, b) => a.id - b.id);
                setFilterSelected('asc');
                setAllCollectorList(sortedData);
                setOriginalCollectorList(storeRawData);
            } catch (error) {
                console.error('Erreur lors de la récupération des collectors', error);
            }
        };
        fetchAllCollectors();
    }, [userContext.entityId]);

    useEffect(() => {
        const fetchCollectorNames = async () => {
            try {
                if (searchTerm.length > 1) {
                    const result = originalCollectorList.filter((item) => item.id.toString().includes(searchTerm));

                    if (result.length) {
                        setAllCollectorList(result);
                        setCollectorNames(result);
                        setErrorMessage('');
                    }
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des collecteurs', error);
            }
        };

        fetchCollectorNames();
    }, [searchTerm, originalCollectorList]);

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
        setShowOptions(true);
    };

    const handleSelectOption = (option) => {
        setSearchTerm(option.name);
        setShowOptions(false);
        navigate('/storeowner/operation/collector/sheet', { state: { id: option.id } });
    };

    const sortDataAsc = () => {
        const sortedData = [...AllCollectorList].sort((a, b) => a.id - b.id);
        setFilterSelected('asc');
        setAllCollectorList(sortedData);
    };

    const sortDataDesc = () => {
        const sortedData = [...AllCollectorList].sort((a, b) => b.id - a.id);
        setFilterSelected('desc');
        setAllCollectorList(sortedData);
    };

    useEffect(() => {
        const fetchAdvancedData = async () => {
            try {
                const data = await getCollectorsByStoreOwnerId(userContext.entityId);
                setOriginalCollectorList(data);

                const typeUnique = [...new Set(data.map((objet) => objet.type))];
                setTypeTab(typeUnique);
                const statusUnique = [...new Set(data.map((objet) => objet.status))];
                setStatusTab(statusUnique);
            } catch (error) {
                console.error('Erreur lors de la récupération des produits', error);
            }
        };

        if (advancedSearch) {
            fetchAdvancedData();
        }
    }, [advancedSearch, userContext.entityId]);

    const handleChange = (e, type) => {
        const selectedValue = e.target.value;

        switch (type) {
            case 'type':
                setSelectedType(selectedValue);
                break;
            case 'zipcode':
                setSelectedStatus(selectedValue);
                break;
            default:
                break;
        }
    };

    const filterData = useCallback(async () => {
        let filteredData = originalCollectorList;

        if (selectedType) {
            let num = parseInt(selectedType);
            filteredData = filteredData.filter((objet) => objet.type === num);
        }

        if (selectedStatus) {
            filteredData = filteredData.filter((objet) => String(objet.status) === selectedStatus);
        }
        setAllCollectorList(filteredData);
    }, [originalCollectorList, selectedType, selectedStatus]);

    useEffect(() => {
        filterData();
    }, [filterData]);

    const handleCollectorClick = (item) => {
        navigate('/storeowner/operation/collector/sheet', { state: { id: item.id } });
    };

    // useEffect(() => {
    //     const fetchData = async (id) => {
    //         try {
    //             const response = await getEventsByCollectorId(id);

    //             const objetsStatus0 = response.filter((objet) => objet.status === 0);
    //             const dernierStatus0 = objetsStatus0[0];
    //             const objetsStatus1AvecSuiviIDCorrespondant = response.filter((objet) => objet.status === 1 && objet.suiviId === dernierStatus0.suiviId);
    //             const dernierStatus1AvecSuiviIDCorrespondant = objetsStatus1AvecSuiviIDCorrespondant[0];
    //             const objetsStatus3AvecMemeSuiviID = response.filter((objet) => objet.status === 3 && objet.suiviId === dernierStatus0.suiviId);
    //             const premierStatus3AvecMemeSuiviID = objetsStatus3AvecMemeSuiviID[0];

    //             const resultat = {
    //                 request: dernierStatus0,
    //                 accept: dernierStatus1AvecSuiviIDCorrespondant,
    //                 done: premierStatus3AvecMemeSuiviID,
    //             };
    //             return resultat;
    //         } catch (error) {
    //             console.error("Une erreur s'est produite : ", error);
    //         }
    //     };
    //     const fetchDataForItems = async () => {
    //         const newData = [];
    //         for (const item of AllCollectorList) {
    //             const collecte = await fetchData(item.id);
    //             newData.push({ ...item, collecte });
    //         }
    //         setData(newData);
    //     };
    //     fetchDataForItems();
    // }, [AllCollectorList]);

    return (
        <main>
            <section className="big-section">
                <h2>Les collecteurs </h2>
                {/* <div
                    className="top"
                    style={{ flexDirection: 'column' }}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                        <SearchComponent
                            dropdownRef={dropdownRef}
                            showOptions={showOptions}
                            searchTerm={searchTerm}
                            handleInputChange={handleInputChange}
                            productsNames={collectorNames}
                            handleSelectOption={handleSelectOption}
                            placeholder="Rechercher un collecteur"
                            searchKey="id"
                        />

                        <div className="top-button-sort">
                            <div className="filter">
                                <p className="sort">Trier par</p>
                                <div>
                                    <p
                                        className={filterSelected === 'asc' ? 'selected' : 'classic'}
                                        onClick={sortDataAsc}>
                                        A &gt; Z
                                    </p>
                                    <p
                                        className={filterSelected === 'desc' ? 'selected' : 'classic'}
                                        onClick={sortDataDesc}>
                                        Z &gt; A
                                    </p>
                                </div>
                            </div>
                            <div
                                onClick={() => {
                                    setAdvancedSearch(!advancedSearch);
                                }}
                                className="button-actif">
                                Recherche avancée {advancedSearch ? <BiChevronUp size={20} /> : <BiChevronDown size={20} />}
                            </div>
                        </div>
                    </div>

                    {advancedSearch && (
                        <div
                            className="advanced-search"
                            style={{ display: 'flex', marginTop: '1.25rem', gap: '1.25rem', justifyContent: 'end' }}>
                            <select
                                id="city"
                                value={selectedType}
                                onChange={(e) => handleChange(e, 'type')}>
                                <option value="">Type</option>
                                {typeTab.length > 0 &&
                                    typeTab.map((type, index) => (
                                        <option
                                            key={index}
                                            value={type}>
                                            {_defineCollectorType(type)}
                                        </option>
                                    ))}
                            </select>
                            <select
                                id="sku"
                                value={selectedStatus}
                                onChange={(e) => handleChange(e, 'zipcode')}>
                                <option value="">Sélectionnez un statut</option>
                                {statusTab.length > 0 &&
                                    statusTab.map((status, index) => (
                                        <option
                                            key={index}
                                            value={status}>
                                            {_defineStatus(status).name}
                                        </option>
                                    ))}
                            </select>
                        </div>
                    )}
                </div> */}
                {errorMessage && (
                    <div style={{ width: '100%', padding: '0.5rem', textAlign: 'center', color: Constant.$rouge, marginBottom: '1.25rem' }}>
                        <p>{errorMessage}</p>
                    </div>
                )}
                <div className="tab-item">
                    <div
                        className="pre-filled-fields-1-several-item"
                        style={{ fontWeight: '600', width: '100%' }}>
                        <p>ID</p>
                        <p>Type</p>
                        <p>Fabricant</p>
                        <p>Modèle</p>
                        <p>Identifiant</p>
                        <p>Capacité</p>
                        <p>Statut</p>
                        <p>Tx remplissage</p>
                        <p>Date demande collecte</p>
                    </div>
                    {AllCollectorList.length
                        ? AllCollectorList.map((item, index) => {
                              //   const dateRequest = item.collecte.request ? parseISO(item.collecte.request.createdAt) : '';

                              return (
                                  <div
                                      style={{ display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'space-between' }}
                                      key={index}>
                                      <div
                                          style={{ width: '100%', cursor: 'pointer' }}
                                          onClick={() => {
                                              handleCollectorClick(item);
                                          }}
                                          className="pre-filled-fields-2-several-item">
                                          <p>{item.id}</p>
                                          <p>{_defineCollectorType(item.type)}</p>
                                          <p>{item.supplier_name}</p>
                                          <p>{item.supplier_model}</p>
                                          <p>{item.device_id}</p>
                                          <p>{item.capacity}</p>
                                          <p
                                              style={{
                                                  color: Constant.$blanc,
                                                  backgroundColor: _defineCollecteurStatus(item.status).color,
                                              }}>
                                              {_defineCollecteurStatus(item.status).name}
                                          </p>
                                          <p>{item.filling_rate}</p>
                                          {/* <p>
                                              {(item.collecte && !item.collecte.done && item.collecte.request && format(dateRequest, 'yyyy-MM-dd HH:mm:ss')) ||
                                                  'N.D'}
                                          </p> */}
                                          <p>/</p>
                                      </div>
                                  </div>
                              );
                          })
                        : null}
                </div>
            </section>
        </main>
    );
}
