import { useEffect } from 'react';
import * as Constants from './Constants.js';

export const handleArrowClickDown = () => {
    // Fonction pour faire défiler la page vers le bas
    window.scrollTo({
        top: window.scrollY + 200, // Par exemple, faites défiler de 200 pixels vers le bas
        behavior: 'smooth', // Défilement fluide
    });
};
export const getManualTicks = (data, keys) => {
    const arr = [];
    for (let i = 0; i < keys.length; i++) {
        data.map((a) => arr.push(a[keys[i]]));
    }

    const max = Math.max(...arr);
    const roundMax = Math.ceil(max / 100) * 100;
    const finalArray = [0];
    const firstQuarter = roundMax / 4;
    const secondQuarter = roundMax / 2;
    const thirdQuarter = roundMax * (3 / 4);

    finalArray.push(firstQuarter, secondQuarter, thirdQuarter, roundMax);
    return finalArray;
};

export const transformDate = (date) => {
    return new Date(date).toISOString().substr(0, 10);
};

export const transformDateWH = (date) => {
    return new Date(date).toISOString().slice(0, 13).replace('T', ' ') + 'h';
};

export const transformDateFull = (date) => {
    return new Date(date).toISOString().slice(0, 19).replace('T', ' ');
};

export const diffDate = (firstDate, secondDate) => {
    // via https://stackoverflow.com/questions/2536379/difference-in-months-between-two-dates-in-javascript
    let months;
    let d1 = new Date(firstDate);
    let d2 = new Date(secondDate);

    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();

    return `${months} mois`;
};

export const _defineStatus = (status) => {
    switch (status) {
        case 0:
            return { color: 'green', name: 'Actif' };
        case 100:
            return { color: 'red', name: 'incatif' };
        default:
            return { color: 'grey', name: 'N.D' };
    }
};

export const _defineCount = (Count) => {
    switch (Count) {
        case 0:
            return { backgroundcolor: 'white', color: 'red', fontweight: 'bold', fontSize: '1.2rem' };
        default:
            return { backgroundcolor: 'white', color: 'black', fontweight: 'normal', fontSize: '1.0rem' };
    }
};

export const _defineStatusBool = (status) => {
    switch (status) {
        case false:
            return { color: 'green', name: 'Actif' };
        case true:
            return { color: 'red', name: 'inactif' };
        default:
            return { color: 'grey', name: 'N.D' };
    }
};

export const _defineCollecteurStatus = (status) => {
    switch (status) {
        case 0:
            return { color: 'green', name: 'Actif' };
        case 100:
            return { color: 'grey', name: 'Désactivé' };
        default:
            return { color: 'grey', name: 'N.D' };
    }
};

export const _defineUserRole = (role) => {
    switch (role) {
        case Constants.ENTITY_ROLE_ADMIN:
            return { color: 'green', name: 'Administrateur' };
        case Constants.ENTITY_ROLE_CONTRIBUTOR:
            return { color: 'violet', name: 'Contributeur' };
        case Constants.ENTITY_ROLE_VIEWER:
            return { color: 'blue', name: 'Lecteur' };
        default:
            return { color: 'grey', name: 'N.D' };
    }
};

export const _defineClientType = (type) => {
    switch (type) {
        case 0:
            return { color: '#38B49F', name: 'Fournisseur de service' };
        case 1:
            return { color: '#FEA31F', name: 'Marque' };
        case 2:
            return { color: '#72BAE3', name: 'Centre de tri' };
        case 3:
            return { color: '#F1C93B', name: 'Centre de lavage' };
        case 4:
            return { color: '#A082BA', name: 'Operateur collecte' };
        case 5:
            return { color: '#DD7875', name: 'Gestionnaire de RVM' };
        case 6:
            return { color: '#D778D5', name: 'Gestionnaire de Magasin' };
        case 7:
            return { color: '#D7112F', name: 'Chambre de compensation' };
        case 8:
            return { color: '#c4e456', name: 'Enseigne' };
        default:
            return { color: 'grey', name: 'N.D' };
    }
};

export const _defineCollecteType = (type) => {
    switch (type) {
        case Constants.COLLECTETYPE_STORE:
            return 'Magasin';
        case Constants.COLLECTETYPE_SORTER:
            return 'Centre de tri';
        case Constants.COLLECTETYPE_WASHER:
            return 'Centre de lavage';
        default:
            return 'N.D';
    }
};
export const _defineProductCat = (cat) => {
    const numericType = parseInt(cat);

    switch (numericType) {
        case Constants.INDUSPRODUCT_CAT_EPICERIESALEE:
            return 'Epicerie salée';
        case Constants.INDUSPRODUCT_CAT_EPICERIESUCREE:
            return 'Epicerie sucrée';
        case Constants.INDUSPRODUCT_CAT_PRODUITSFRAIS:
            return 'Produits frais';
        case Constants.INDUSPRODUCT_CAT_BOISSON:
            return 'Boisson';
        default:
            return 'N.D';
    }
};

export const productCatTab = [
    Constants.INDUSPRODUCT_CAT_EPICERIESALEE,
    Constants.INDUSPRODUCT_CAT_EPICERIESUCREE,
    Constants.INDUSPRODUCT_CAT_PRODUITSFRAIS,
    Constants.INDUSPRODUCT_CAT_BOISSON,
];

export const useClickOutside = (ref, callback) => {
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [ref, callback]);
};

export const handleDelete = async (id, deleteFunction, setDataFunction) => {
    try {
        if (window.confirm(`Voulez-vous vraiment supprimer cet élément ?`)) {
            await deleteFunction(id);
            setDataFunction((prevData) => prevData.filter((item) => item.id !== id));
        }
    } catch (error) {
        console.error("Erreur lors de la suppression de l'élément", error);
    }
};

export const _defineContainerUsage = (usage) => {
    switch (usage) {
        case Constants.CONTAINER_USAGE_PRIMARY:
            return 'Primaire';
        case Constants.CONTAINER_USAGE_SECONDARY:
            return 'Secondaire';
        default:
            return 'N.D';
    }
};

export const containerUsageTab = [
    Constants.CONTAINER_USAGE_PRIMARY,
    Constants.CONTAINER_USAGE_SECONDARY,
];

export const _defineContainerCat = (cat) => {
    switch (cat) {
        case Constants.CONTAINER_CAT_FRESHPRODUCT:
            return 'Produits frais';
        case Constants.CONTAINER_CAT_BEVERAGE:
            return 'Boisson';
        case Constants.CONTAINER_CAT_CATERING:
            return 'Restauration';
        default:
            return 'N.D';
    }
};

export const containerCatTab = [
    Constants.CONTAINER_CAT_FRESHPRODUCT,
    Constants.CONTAINER_CAT_BEVERAGE,
    Constants.CONTAINER_CAT_CATERING,
];

export const _defineContainerType = (type) => {
    const numericType = parseInt(type);

    switch (numericType) {
        case Constants.CONTAINER_TYPE_GLASSJAR:
            return 'Bocal';
        case Constants.CONTAINER_TYPE_BOTTLE:
            return 'Bouteille';
        case Constants.CONTAINER_TYPE_TRAY:
            return 'Barquette';
        case Constants.CONTAINER_TYPE_BENTO:
            return 'Bento';
        case Constants.CONTAINER_TYPE_ROUNDBOX:
            return 'Boîte ronde';
        case Constants.CONTAINER_TYPE_BOWL:
            return 'Bol';
        case Constants.CONTAINER_TYPE_CONTAINER:
            return 'Contenant';
        case Constants.CONTAINER_TYPE_CUP:
            return 'Gobelet';
        case Constants.CONTAINER_TYPE_PASTABOX:
            return 'Pasta box';
        case Constants.CONTAINER_TYPE_POT:
            return 'Pot';
        case Constants.CONTAINER_TYPE_OTHER:
            return 'Autre';
        default:
            return 'N.D';
    }
};

export const containerTypesTab = [
    Constants.CONTAINER_TYPE_GLASSJAR,
    Constants.CONTAINER_TYPE_BOTTLE,
    Constants.CONTAINER_TYPE_TRAY,
    Constants.CONTAINER_TYPE_BENTO,
    Constants.CONTAINER_TYPE_ROUNDBOX,
    Constants.CONTAINER_TYPE_BOWL,
    Constants.CONTAINER_TYPE_CONTAINER,
    Constants.CONTAINER_TYPE_CUP,
    Constants.CONTAINER_TYPE_PASTABOX,
    Constants.CONTAINER_TYPE_POT,
    Constants.CONTAINER_TYPE_OTHER,
];

export const _defineContainerSubType = (subtype) => {
    const numericType = parseInt(subtype);

    switch (numericType) {
        case Constants.CONTAINER_SUBTYPE_CARROND:
            return 'Carrond';
        case Constants.CONTAINER_SUBTYPE_ROUND:
            return 'Rond';
        case Constants.CONTAINER_SUBTYPE_EMPTY:
            return 'Vide';
        case Constants.CONTAINER_SUBTYPE_ALIENOR:
            return 'Alienor';
        case Constants.CONTAINER_SUBTYPE_BORDEAUX:
            return 'Bordeaux';
        case Constants.CONTAINER_SUBTYPE_BOURGOGNE:
            return 'Bourgogne';
        case Constants.CONTAINER_SUBTYPE_CHAMPENOISE:
            return 'Champenoise';
        case Constants.CONTAINER_SUBTYPE_SPARKLING:
            return 'Pétillant';
        case Constants.CONTAINER_SUBTYPE_VICHY:
            return 'Vichy';
        case Constants.CONTAINER_SUBTYPE_STEINIE:
            return 'Steinie';
        case Constants.CONTAINER_SUBTYPE_LITREDEFRANCE:
            return 'Litre de France';
        case Constants.CONTAINER_SUBTYPE_LONGNECK:
            return 'Longneck';
        case Constants.CONTAINER_SUBTYPE_FRESHLITER:
            return 'Litre fraicheur';
        case Constants.CONTAINER_SUBTYPE_SQUARE:
            return 'Carré';
        case Constants.CONTAINER_SUBTYPE_ANGLE:
            return 'Réctangle';
        default:
            return 'N.D';
    }
};

export const containerSubTypesTabBowl = [
    Constants.CONTAINER_SUBTYPE_CARROND,
    Constants.CONTAINER_SUBTYPE_ROUND,
];

export const containerSubTypesTabBottle = [
    Constants.CONTAINER_SUBTYPE_EMPTY,
    Constants.CONTAINER_SUBTYPE_ALIENOR,
    Constants.CONTAINER_SUBTYPE_BORDEAUX,
    Constants.CONTAINER_SUBTYPE_BOURGOGNE,
    Constants.CONTAINER_SUBTYPE_CHAMPENOISE,
    Constants.CONTAINER_SUBTYPE_SPARKLING,
    Constants.CONTAINER_SUBTYPE_VICHY,
    Constants.CONTAINER_SUBTYPE_STEINIE,
    Constants.CONTAINER_SUBTYPE_LITREDEFRANCE,
    Constants.CONTAINER_SUBTYPE_LONGNECK,
    Constants.CONTAINER_SUBTYPE_FRESHLITER,
];

export const containerSubTypesTabContainer = [
    Constants.CONTAINER_SUBTYPE_SQUARE,
    Constants.CONTAINER_SUBTYPE_ANGLE,
];

export const _defineContainerMaterial = (material) => {
    const numericType = parseInt(material);

    switch (numericType) {
        case Constants.CONTAINER_MATERIAL_COPOLYESTER:
            return 'Co-Polyester';
        case Constants.CONTAINER_MATERIAL_CPET:
            return 'CPET*';
        case Constants.CONTAINER_MATERIAL_INOX:
            return 'Inox';
        case Constants.CONTAINER_MATERIAL_PBT:
            return 'PBT*';
        case Constants.CONTAINER_MATERIAL_PP:
            return 'PP';
        case Constants.CONTAINER_MATERIAL_PET:
            return 'PET';
        case Constants.CONTAINER_MATERIAL_TRITAN:
            return 'TRITAN';
        case Constants.CONTAINER_MATERIAL_BOROSILICATE:
            return 'Verre borosilicate';
        case Constants.CONTAINER_MATERIAL_SODOCALCIQUE:
            return 'Verre sodocalcique';
        case Constants.CONTAINER_MATERIAL_OTHER:
            return 'Autre';
        default:
            return 'N.D';
    }
};

export const containerMaterialTab = [
    Constants.CONTAINER_MATERIAL_COPOLYESTER,
    Constants.CONTAINER_MATERIAL_CPET,
    Constants.CONTAINER_MATERIAL_INOX,
    Constants.CONTAINER_MATERIAL_PBT,
    Constants.CONTAINER_MATERIAL_PP,
    Constants.CONTAINER_MATERIAL_PET,
    Constants.CONTAINER_MATERIAL_TRITAN,
    Constants.CONTAINER_MATERIAL_BOROSILICATE,
    Constants.CONTAINER_MATERIAL_SODOCALCIQUE,
    Constants.CONTAINER_MATERIAL_OTHER,
];

export const _defineContainerOwnerStatus = (owner) => {
    const numericType = parseInt(owner);

    switch (numericType) {
        case Constants.CONTAINER_OWNER_STATUS_STANDARD:
            return 'Standard';
        case Constants.CONTAINER_OWNER_STATUS_BRAND:
            return 'Iconique';
        default:
            return 'N.D';
    }
};

export const containerOwnerStatusTab = [Constants.CONTAINER_OWNER_STATUS_STANDARD, Constants.CONTAINER_OWNER_STATUS_BRAND];

export const _defineContainerPaletType = (paletType) => {
    switch (paletType) {
        case Constants.CONTAINER_PALETTYPE_40X60:
            return '40x60';
        case Constants.CONTAINER_PALETTYPE_40X80:
            return '40x80';
        case Constants.CONTAINER_PALETTYPE_80X100:
            return '80x100';
        case Constants.CONTAINER_PALETTYPE_80X120:
            return '80x120';
        case Constants.CONTAINER_PALETTYPE_100X120:
            return '100x120';
        case Constants.CONTAINER_PALETTYPE_120X120:
            return '120x120';
        default:
            return 'N.D';
    }
};

export const containerPaletTypeTab = [
    Constants.CONTAINER_PALETTYPE_40X60,
    Constants.CONTAINER_PALETTYPE_40X80,
    Constants.CONTAINER_PALETTYPE_80X100,
    Constants.CONTAINER_PALETTYPE_80X120,
    Constants.CONTAINER_PALETTYPE_100X120,
    Constants.CONTAINER_PALETTYPE_120X120,
];

export const _defineContainerUnit = (unit) => {
    switch (unit) {
        case Constants.CONTAINER_UNIT_ML:
            return 'ml';
        case Constants.CONTAINER_UNIT_GR:
            return 'gr';
        case Constants.CONTAINER_UNIT_CM3:
            return 'cm3';
        default:
            return 'N.D';
    }
};

export const containerUnitTab = [Constants.CONTAINER_UNIT_ML, Constants.CONTAINER_UNIT_GR, Constants.CONTAINER_UNIT_CM3];

export const _defineStoreType = (type) => {
    const numericType = parseInt(type);
    switch (numericType) {
        case Constants.STORE_TYPE_HYPER:
            return 'Hyper';
        case Constants.STORE_TYPE_SUPER:
            return 'Super';
        case Constants.STORE_TYPE_PROXI:
            return 'Proxi';
        default:
            return 'N.D';
    }
};

export const storeTypeTab = [Constants.STORE_TYPE_HYPER, Constants.STORE_TYPE_SUPER, Constants.STORE_TYPE_PROXI];

export const _defineStoreOwner = (type) => {
    const numericType = parseInt(type);
    switch (numericType) {
        case Constants.STORE_OWNER_COLLECTIVITE:
            return 'Collectivité';
        case Constants.STORE_OWNER_MAGASIN:
            return 'Magasin';
        default:
            return 'N.D';
    }
};

export const storeOwnerTab = [Constants.STORE_OWNER_COLLECTIVITE, Constants.STORE_OWNER_MAGASIN];

export const _defineBoxesStoreStatus = (type) => {
    const numericType = parseInt(type);
    switch (numericType) {
        case Constants.STORESBOXES_STATUS_INSTORE:
            return 'En magasin';
        case Constants.STORESBOXES_STATUS_RETURNED:
            return 'Retourné';

        default:
            return 'N.D';
    }
};
export const _defineBoxesStoreState = (type) => {
    const numericType = parseInt(type);
    switch (numericType) {
        case Constants.STORESBOXES_STATE_FILL:
            return 'Pleine';
        case Constants.STORESBOXES_STATE_EMPTY:
            return 'Vide';
        default:
            return 'N.D';
    }
};

export const _defineStoreEventType = (type) => {
    const numericType = parseInt(type);
    switch (numericType) {
        case Constants.STOREEVENT_TYPE_COLLECTE:
            return 'Collecte';
        case Constants.STOREEVENT_TYPE_DELIVERY:
            return 'Livraison';
        default:
            return 'N.D';
    }
};

export const _defineCollectorType = (type) => {
    const numericType = parseInt(type);
    switch (numericType) {
        case Constants.COLLECTOR_TYPE_RVM:
            return 'RVM';
        case Constants.COLLECTOR_TYPE_AUTO:
            return 'Automatique';
        case Constants.COLLECTOR_TYPE_TCE:
            return 'TCE';
        case Constants.COLLECTOR_TYPE_CASHIER:
            return 'Caisse';
        default:
            return 'N.D';
    }
};

export const collectorTypeTab = [
    Constants.COLLECTOR_TYPE_RVM,
    Constants.COLLECTOR_TYPE_AUTO,
    Constants.COLLECTOR_TYPE_TCE,
    Constants.COLLECTOR_TYPE_CASHIER,
];

export const _defineContainerPrice = (type) => {
    const numericType = parseInt(type);

    switch (numericType) {
        case Constants.CONTAINER_TYPE_GLASSJAR:
            return 10;
        case Constants.CONTAINER_TYPE_BOTTLE:
            return 20;
        case Constants.CONTAINER_TYPE_TRAY:
            return 40;
        case Constants.CONTAINER_TYPE_BENTO:
            return 20;
        case Constants.CONTAINER_TYPE_ROUNDBOX:
            return 30;
        case Constants.CONTAINER_TYPE_BOWL:
            return 100;
        case Constants.CONTAINER_TYPE_CONTAINER:
            return 20;
        case Constants.CONTAINER_TYPE_CUP:
            return 10;
        case Constants.CONTAINER_TYPE_PASTABOX:
            return 30;
        case Constants.CONTAINER_TYPE_POT:
            return 20;
        case Constants.CONTAINER_TYPE_OTHER:
            return 20;
        default:
            return 'N.D';
    }
};

export const _defineCollecteStatus = (status) => {
    switch (status) {
        case Constants.STOREEVENT_ASKED:
            return { color: 'lightblue', name: 'Envoyé' };
        case Constants.EVENT_REQUESTED:
            return { color: 'orange', name: 'Demandé' };
        case Constants.EVENT_ACCEPTED:
            return { color: 'green', name: 'Accepté ' };
        case Constants.EVENT_DONE:
            return { color: 'grey', name: 'Collecté' };
        case Constants.EVENT_RECEIVED:
            return { color: 'grey', name: 'Livrée' };
        default:
            return { color: 'grey', name: 'N.D' };
    }
};

export const _defineOrderStatus = (status) => {
    switch (status) {
        case Constants.ORDERDETAILS_PENDING:
            return { color: 'red', name: 'En attente' };
        case Constants.ORDERDETAILS_SUPPLIER_ATTRIBUTED:
            return { color: 'orange', name: 'Attribuée Verrier' };
        case Constants.ORDERDETAILS_WASHER_ATTRIBUTED:
            return { color: 'green', name: 'Attribuée Laveur' };
        case Constants.ORDERDETAILS_RECEIVED:
            return { color: 'grey', name: 'Reçue' };
        default:
            return { color: 'grey', name: 'N.D' };
    }
};

export const _defineDeclarationDetailsStatus = (status) => {
    switch (status) {
        case Constants.DECLARATION_DETAILS_AVAILABLE:
            return { color: 'orange', name: 'Disponible' };
        case Constants.DECLARATION_DETAILS_ATTRIBUTED:
            return { color: 'orange', name: 'Attribué ' };
        case Constants.DECLARATION_DETAILS_VALIDATED:
            return { color: 'green', name: 'Pris en compte' };
        default:
            return { color: 'grey', name: 'N.D' };
    }
};

export const _defineModule = (module) => {
    switch (module) {
        case Constants.MODULE_OPERATION:
            return 'OPERATION';
        case Constants.MODULE_DATA:
            return 'DATA';
        case Constants.MODULE_FACTURATION:
            return 'FACTURATION';
        default:
            return 'N.D';
    }
};

export const _defineRemboursementType = (type) => {
    const numericType = parseInt(type);
    switch (numericType) {
        case Constants.COLLECTORRETURN_TYPE_BRI:
            return 'BRI';
        case Constants.COLLECTORRETURN_TYPE_CB:
            return 'CB';
        default:
            return 'N.D';
    }
};

// VALIDATION FORMULAIRE CONTAINER

export const isNumber = (input) => {
    const numberRegex = /^\d+$/;
    return numberRegex.test(input);
};
export const isDeviceId = (texte) => {
    const regex = /^.{0,20}$/;
    return regex.test(texte);
};
export const isEAN13 = (input) => {
    const ean13Regex = /^(?=(?:\D*\d){13}\D*$)\d{13}$/;
    return ean13Regex.test(input);
};

export const isShortText25Characters = (texte) => {
    const regex = /^.{0,25}$/;
    return regex.test(texte);
};

export const isCity = (city) => {
    const hasNumbers = /\d/.test(city);
    if (hasNumbers) {
        return false;
    }
    const regex = /^.{0,25}$/;
    return regex.test(city);
};

export const isLongText64Characters = (texte) => {
    const regex = /^.{0,64}$/;
    return regex.test(texte);
};

export const isLongText256Characters = (texte) => {
    const regex = /^.{0,256}$/;
    return regex.test(texte);
};

export const isSiret = (siret) => {
    const siretRegex = /^\d{14}$/;
    return siretRegex.test(siret);
};

export const isTVAIntracommunautaire = (tva) => {
    const tvaRegex = /^(?:AT|BE|BG|CY|CZ|DE|DK|EE|EL|ES|FI|FR|GB|HR|HU|IE|IT|LT|LU|LV|MT|NL|PL|PT|RO|SE|SI|SK)[0-9A-Z]{2,13}$/;
    return tvaRegex.test(tva);
};

export const isCodePostal = (codePostal) => {
    const codePostalRegex = /^(?:[0-8]\d|9[0-8])\d{3}$/;
    return codePostalRegex.test(codePostal);
};

export const isTelephone = (telephone) => {
    const telephoneRegex = /^(?:(?:(?:\+|00)33[\s.-]?)|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{2}){3})$/;
    return telephoneRegex.test(telephone);
};

export const isEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

export const isSkuValid = (texte) => {
    const regex = /^.{0,25}$/;
    return regex.test(texte);
};

export const isWeightValid = (weight) => {
    return weight > 0 && weight < 10000;
};

export const isHeightValid = (height) => {
    return height > 0 && height < 10000;
};

export const isPaletNumberValid = (number) => {
    return number > 0 && number < 10000;
};

export const isWidthValid = (width) => {
    return width > 0 && width < 10000;
};

export const isLengthValid = (length) => {
    return length > 0 && length < 10000;
};
export const isUnitValueValid = (length) => {
    return length > 0 && length < 10000;
};

export const isDimHeightWithoutCoverValid = (dim_height_without_cover) => {
    return dim_height_without_cover > 0 && dim_height_without_cover < 10000;
};

export const isDimHeightWithCoverValid = (dim_height_with_cover) => {
    return dim_height_with_cover > 0 && dim_height_with_cover < 10000;
};
export const isDimBaseDiameter = (dim_base_diameter) => {
    return dim_base_diameter > 0 && dim_base_diameter < 10000;
};
export const isDimOpeningDiameter = (dim_opening_diameter) => {
    return dim_opening_diameter > 0 && dim_opening_diameter < 10000;
};

export const isURLValide = (url) => {
    const regex = /^(ftp|http|https):\/\/[^ "]+$/;
    return regex.test(url);
};

export const checkContainerFormErrors = (formData, formErrors, setFormErrors, setErrorMessage) => {
    const errors = {};
    const errorMessageTab = [];

    if (!isShortText25Characters(formData.name)) {
        errors.name = true;
        errorMessageTab.push('Le nom doit faire moins de 25 caractères');
    } else {
        errors.name = false;
    }
    if (!isSkuValid(formData.sku)) {
        errors.sku = true;
        errorMessageTab.push('Le SKU doit avoir le format XXXX');
    } else {
        errors.sku = false;
    }
    if (!isUnitValueValid(formData.unitValue)) {
        errors.unitValue = true;
        errorMessageTab.push("La valeur de l'unité doit être un nombre");
    } else {
        errors.unitValue = false;
    }
    if (!isWeightValid(formData.weight)) {
        errors.weight = true;
        errorMessageTab.push('Le poids doit être un nombre inferieur à 10000');
    } else {
        errors.weight = false;
    }
    if (!isPaletNumberValid(formData.palet_nbcontainer)) {
        errors.palet_nbcontainer = true;
        errorMessageTab.push('Le nombre de contenants par palette doit être inferieur à 10000');
    } else {
        errors.palet_nbcontainer = false;
    }
    if (formData.dim_width && !isWidthValid(formData.dim_width)) {
        errors.dim_width = true;
        errorMessageTab.push('La largeur doit être inferieure à 10000');
    } else {
        errors.dim_width = false;
    }
    if (formData.dim_length && !isLengthValid(formData.dim_length)) {
        errors.dim_length = true;
        errorMessageTab.push('La longueur doit être inferieure à 10000');
    } else {
        errors.dim_length = false;
    }
    if (!isDimHeightWithoutCoverValid(formData.dim_height_without_cover)) {
        errors.dim_height_without_cover = true;
        errorMessageTab.push('La hauteur sans couvercle doit être inferieure à 10000');
    } else {
        errors.dim_height_without_cover = false;
    }
    if (!isDimHeightWithCoverValid(formData.dim_height_with_cover)) {
        errors.dim_height_with_cover = true;
        errorMessageTab.push('La hauteur avec couvercle doit être inferieure à 10000');
    } else {
        errors.dim_height_with_cover = false;
    }
    if (formData.dim_base_diameter && !isDimBaseDiameter(formData.dim_base_diameter)) {
        errors.dim_base_diameter = true;
        errorMessageTab.push('La longeur du diamètre de la base doit être inferieure à 10000');
    } else {
        errors.dim_base_diameter = false;
    }
    if (formData.dim_opening_diameter && !isDimOpeningDiameter(formData.dim_opening_diameter)) {
        errors.dim_opening_diameter = true;
        errorMessageTab.push("La longeur du diamètre de l'ouverture doit être inferieure à 10000");
    } else {
        errors.dim_opening_diameter = false;
    }
    if (formData.url && !isURLValide(formData.url)) {
        errors.url = true;
        errorMessageTab.push("L'url n'est pas valide");
    } else {
        errors.url = false;
    }
    setFormErrors({ ...formErrors, ...errors });
    setErrorMessage(errorMessageTab);
    if (errorMessageTab.length === 0) {
        return false;
    } else {
        setErrorMessage(errorMessageTab);
        return true;
    }
};

// POUR ENTITY

export const checkEntityFormErrors = (formData, formErrors, setFormErrors, setErrorMessage) => {
    const errors = {};
    const errorMessageTab = [];

    if (!isShortText25Characters(formData.company_name)) {
        errors.company_name = true;
        errorMessageTab.push('Le nom industriel doit faire moins de 25 caractères');
    } else {
        errors.company_name = false;
    }
    if (!isShortText25Characters(formData.name)) {
        errors.name = true;
        errorMessageTab.push('Le nom doit faire moins de 25 caractères');
    } else {
        errors.name = false;
    }
    if (!isSiret(formData.siret)) {
        errors.siret = true;
        errorMessageTab.push("Le format du numéro de SIRET n'est pas valide");
    } else {
        errors.siret = false;
    }
    if (!isTVAIntracommunautaire(formData.tva_intra)) {
        errors.tva_intra = true;
        errorMessageTab.push("Le format du numéro tva intracommunautaire n'est pas valide");
    } else {
        errors.tva_intra = false;
    }
    if (!isLongText64Characters(formData.address_1)) {
        errors.address_1 = true;
        errorMessageTab.push("L'adresse doit faire moins de 64 caractères");
    } else {
        errors.address_1 = false;
    }
    if (formData.address_2 && !isLongText64Characters(formData.address_2)) {
        errors.address_2 = true;
        errorMessageTab.push("L'adresse doit faire moins de 64 caractères");
    } else {
        errors.address_2 = false;
    }
    if (!isCodePostal(formData.zipcode)) {
        errors.zipcode = true;
        errorMessageTab.push('La code postale est invalide');
    } else {
        errors.zipcode = false;
    }
    if (!isCity(formData.city)) {
        errors.city = true;
        errorMessageTab.push('La ville doit faire moins de 64 caractères');
    } else {
        errors.city = false;
    }
    if (!isShortText25Characters(formData.contact_name)) {
        errors.contact_name = true;
        errorMessageTab.push('Le nom du contact doit faire moins de 25 caractères');
    } else {
        errors.contact_name = false;
    }
    if (!isTelephone(formData.contact_phone)) {
        errors.contact_phone = true;
        errorMessageTab.push("Le numéro de téléphone n'est pas valide");
    } else {
        errors.contact_phone = false;
    }
    if (!isEmail(formData.contact_email)) {
        errors.contact_email = true;
        errorMessageTab.push("L'email n'est pas valide");
    } else {
        errors.contact_email = false;
    }
    if (formData.website && !isURLValide(formData.website)) {
        errors.website = true;
        errorMessageTab.push("Le site web n'est pas valide");
    } else {
        errors.website = false;
    }
    setFormErrors({ ...formErrors, ...errors });
    setErrorMessage(errorMessageTab);
    if (errorMessageTab.length === 0) {
        return false;
    } else {
        setErrorMessage(errorMessageTab);
        return true;
    }
};

export const checkIsInputEmpty = (requiredFields, formData, setFormErrors, setErrorEmpty) => {
    const updatedFormErrors = {};
    requiredFields.forEach((field) => {
        if (!formData[field] || (typeof formData[field] === 'string' && formData[field].trim() === '') || formData[field] === 0) {
            updatedFormErrors[field] = true;
        } else {
            updatedFormErrors[field] = false;
        }
    });
    setFormErrors(updatedFormErrors);

    const hasError = Object.values(updatedFormErrors).some((error) => error);
    if (hasError) {
        setErrorEmpty('Merci de remplir tous les champs obligatoires.');
        return true;
    } else {
        setErrorEmpty('');
        return false;
    }
};

// POUR SUPPLIER ADD
export const checkSupplierFormErrors = (formData, formErrors, setFormErrors, setErrorMessage) => {
    const errors = {};
    const errorMessageTab = [];

    if (!isShortText25Characters(formData.name)) {
        errors.name = true;
        errorMessageTab.push('Le nom doit faire moins de 25 caractères');
    } else {
        errors.name = false;
    }
    if (!isSiret(formData.siret)) {
        errors.siret = true;
        errorMessageTab.push("Le format du numéro de SIRET n'est pas valide");
    } else {
        errors.siret = false;
    }
    if (!isTVAIntracommunautaire(formData.tva_intra)) {
        errors.tva_intra = true;
        errorMessageTab.push("Le format du numéro tva intracommunautaire n'est pas valide");
    } else {
        errors.tva_intra = false;
    }
    if (!isLongText64Characters(formData.address_1)) {
        errors.address_1 = true;
        errorMessageTab.push("L'adresse doit faire moins de 64 caractères");
    } else {
        errors.address_1 = false;
    }
    if (formData.address_2 && !isLongText64Characters(formData.address_2)) {
        errors.address_2 = true;
        errorMessageTab.push("L'adresse doit faire moins de 64 caractères");
    } else {
        errors.address_2 = false;
    }
    if (!isCodePostal(formData.zipcode)) {
        errors.zipcode = true;
        errorMessageTab.push('La code postale est invalide');
    } else {
        errors.zipcode = false;
    }
    if (!isCity(formData.city)) {
        errors.city = true;
        errorMessageTab.push('La ville doit faire moins de 64 caractères');
    } else {
        errors.city = false;
    }
    if (!isShortText25Characters(formData.contact_name)) {
        errors.contact_name = true;
        errorMessageTab.push('Le nom du contact doit faire moins de 25 caractères');
    } else {
        errors.contact_name = false;
    }
    if (!isTelephone(formData.contact_phone)) {
        errors.contact_phone = true;
        errorMessageTab.push("Le numéro de téléphone n'est pas valide");
    } else {
        errors.contact_phone = false;
    }
    if (!isEmail(formData.contact_email)) {
        errors.contact_email = true;
        errorMessageTab.push("L'email n'est pas valide");
    } else {
        errors.contact_email = false;
    }
    if (formData.website && !isURLValide(formData.website)) {
        errors.website = true;
        errorMessageTab.push("Le site web n'est pas valide");
    } else {
        errors.website = false;
    }
    if (formData.comments && !isLongText256Characters(formData.comments)) {
        errors.comments = true;
        errorMessageTab.push('Le commentaire doit faire moins de 256 caractères');
    } else {
        errors.comments = false;
    }
    setFormErrors({ ...formErrors, ...errors });
    setErrorMessage(errorMessageTab);
    if (errorMessageTab.length === 0) {
        return false;
    } else {
        setErrorMessage(errorMessageTab);
        return true;
    }
};

// POUR SUPPLIER ADD
export const checkEntityAdressFormErrors = (formData, formErrors, setFormErrors, setErrorMessage) => {
    const errors = {};
    const errorMessageTab = [];

    if (!isNumber(formData.entity_id)) {
        errors.entity_id = true;
        errorMessageTab.push('Le nom de la marque est invalide');
    } else {
        errors.entity_id = false;
    }
    if (!isShortText25Characters(formData.name)) {
        errors.name = true;
        errorMessageTab.push('Le nom doit faire moins de 25 caractères');
    } else {
        errors.name = false;
    }
    if (!isLongText64Characters(formData.address_1)) {
        errors.address_1 = true;
        errorMessageTab.push("L'adresse doit faire moins de 64 caractères");
    } else {
        errors.address_1 = false;
    }
    if (formData.address_2 && !isLongText64Characters(formData.address_2)) {
        errors.address_2 = true;
        errorMessageTab.push("L'adresse doit faire moins de 64 caractères");
    } else {
        errors.address_2 = false;
    }
    if (!isCodePostal(formData.zipcode)) {
        errors.zipcode = true;
        errorMessageTab.push('La code postale est invalide');
    } else {
        errors.zipcode = false;
    }
    if (!isCity(formData.city)) {
        errors.city = true;
        errorMessageTab.push('La ville doit faire moins de 64 caractères');
    } else {
        errors.city = false;
    }
    if (!isShortText25Characters(formData.contact_name)) {
        errors.contact_name = true;
        errorMessageTab.push('Le nom du contact doit faire moins de 25 caractères');
    } else {
        errors.contact_name = false;
    }
    if (!isTelephone(formData.contact_phone)) {
        errors.contact_phone = true;
        errorMessageTab.push("Le numéro de téléphone n'est pas valide");
    } else {
        errors.contact_phone = false;
    }
    if (!isEmail(formData.contact_email)) {
        errors.contact_email = true;
        errorMessageTab.push("L'email n'est pas valide");
    } else {
        errors.contact_email = false;
    }
    if (formData.comments && !isLongText256Characters(formData.comments)) {
        errors.comments = true;
        errorMessageTab.push('Le commentaire doit faire moins de 256 caractères');
    } else {
        errors.comments = false;
    }
    if (formData.delivery_window && !isLongText256Characters(formData.delivery_window)) {
        errors.delivery_window = true;
        errorMessageTab.push('Le commentaire doit faire moins de 64 caractères');
    } else {
        errors.delivery_window = false;
    }
    setFormErrors({ ...formErrors, ...errors });
    setErrorMessage(errorMessageTab);
    if (errorMessageTab.length === 0) {
        return false;
    } else {
        setErrorMessage(errorMessageTab);
        return true;
    }
};

// POUR INDSUTRAIL PRODUCT ADD
export const checkIndustrialProductFormErrors = (formData, formErrors, setFormErrors, setErrorMessage) => {
    const errors = {};
    const errorMessageTab = [];

    if (!isNumber(formData.entity_id)) {
        errors.entity_id = true;
        errorMessageTab.push('Le nom de la marque est invalide');
    } else {
        errors.entity_id = false;
    }
    if (!isShortText25Characters(formData.name)) {
        errors.name = true;
        errorMessageTab.push('Le nom doit faire moins de 25 caractères');
    } else {
        errors.name = false;
    }
    if (!isEAN13(formData.ean)) {
        errors.ean = true;
        errorMessageTab.push("L'EAN doit contenir 13 chiffres");
    } else {
        errors.ean = false;
    }
    if (!isSkuValid(formData.sku)) {
        errors.sku = true;
        errorMessageTab.push('Le SKU est invalide');
    } else {
        errors.sku = false;
    }
    if (!isUnitValueValid(formData.unitValue)) {
        errors.unitValue = true;
        errorMessageTab.push("La valeur de l'unité doit être un nombre compris entre 0 et 10000.");
    } else {
        errors.unitValue = false;
    }

    setFormErrors({ ...formErrors, ...errors });
    setErrorMessage(errorMessageTab);
    if (errorMessageTab.length === 0) {
        return false;
    } else {
        setErrorMessage(errorMessageTab);
        return true;
    }
};

// POUR SUPPLIER PRODUCT ADD
export const checkSupplierProductFormErrors = (formData, formErrors, setFormErrors, setErrorMessage) => {
    const errors = {};
    const errorMessageTab = [];

    if (!isNumber(formData.supplier_id)) {
        errors.supplier_id = true;
        errorMessageTab.push('Le nom du fournisseur est invalide');
    } else {
        errors.supplier_id = false;
    }
    if (!isShortText25Characters(formData.name)) {
        errors.name = true;
        errorMessageTab.push('Le nom doit faire moins de 25 caractères');
    } else {
        errors.name = false;
    }
    if (!isShortText25Characters(formData.ref)) {
        errors.ref = true;
        errorMessageTab.push('La référence fournisseur doit faire moins de 25 caractèress');
    } else {
        errors.ref = false;
    }
    if (!isSkuValid(formData.sku)) {
        errors.sku = true;
        errorMessageTab.push('Le SKU est invalide');
    } else {
        errors.sku = false;
    }

    setFormErrors({ ...formErrors, ...errors });
    setErrorMessage(errorMessageTab);
    if (errorMessageTab.length === 0) {
        return false;
    } else {
        setErrorMessage(errorMessageTab);
        return true;
    }
};

// POUR ADD DEPOT

export const checkDepotFormErrors = (formData, formErrors, setFormErrors, setErrorMessage) => {
    const errors = {};
    const errorMessageTab = [];

    if (!isShortText25Characters(formData.company_name)) {
        errors.company_name = true;
        errorMessageTab.push('Le nom de la société doit faire moins de 25 caractères');
    } else {
        errors.company_name = false;
    }
    if (!isShortText25Characters(formData.name)) {
        errors.name = true;
        errorMessageTab.push('Le nom doit faire moins de 25 caractères');
    } else {
        errors.name = false;
    }
    if (!isNumber(formData.entity_sorter_id)) {
        errors.entity_sorter_id = true;
        errorMessageTab.push("Le centre de tri associé n'est pas valide");
    } else {
        errors.entity_sorter_id = false;
    }

    if (!isLongText64Characters(formData.address_1)) {
        errors.address_1 = true;
        errorMessageTab.push("L'adresse doit faire moins de 64 caractères");
    } else {
        errors.address_1 = false;
    }
    if (formData.address_2 && !isLongText64Characters(formData.address_2)) {
        errors.address_2 = true;
        errorMessageTab.push("L'adresse doit faire moins de 64 caractères");
    } else {
        errors.address_2 = false;
    }
    if (!isCodePostal(formData.zipcode)) {
        errors.zipcode = true;
        errorMessageTab.push('La code postale est invalide');
    } else {
        errors.zipcode = false;
    }
    if (!isCity(formData.city)) {
        errors.city = true;
        errorMessageTab.push('La ville doit être composée de lettres et faire moins de 64 caractères');
    } else {
        errors.city = false;
    }
    if (!isShortText25Characters(formData.contact_name)) {
        errors.contact_name = true;
        errorMessageTab.push('Le nom du contact doit faire moins de 25 caractères');
    } else {
        errors.contact_name = false;
    }
    if (!isTelephone(formData.contact_phone)) {
        errors.contact_phone = true;
        errorMessageTab.push("Le numéro de téléphone n'est pas valide");
    } else {
        errors.contact_phone = false;
    }
    if (!isEmail(formData.contact_email)) {
        errors.contact_email = true;
        errorMessageTab.push("L'email n'est pas valide");
    } else {
        errors.contact_email = false;
    }

    setFormErrors({ ...formErrors, ...errors });
    setErrorMessage(errorMessageTab);
    if (errorMessageTab.length === 0) {
        return false;
    } else {
        setErrorMessage(errorMessageTab);
        return true;
    }
};

// POUR ADD STORE

export const checkStoreFormErrors = (formData, formErrors, setFormErrors, setErrorMessage) => {
    const errors = {};
    const errorMessageTab = [];

    if (!isShortText25Characters(formData.company_name)) {
        errors.company_name = true;
        errorMessageTab.push('Le nom de la société doit faire moins de 25 caractères');
    } else {
        errors.company_name = false;
    }
    if (!isShortText25Characters(formData.name)) {
        errors.name = true;
        errorMessageTab.push('Le nom doit faire moins de 25 caractères');
    } else {
        errors.name = false;
    }
    if (formData.depot_id && !isNumber(formData.depot_id)) {
        errors.depot_id = true;
        errorMessageTab.push("Le centre de collecte n'est pas valide");
    } else {
        errors.depot_id = false;
    }
    if (formData.rvmowner_id && !isNumber(formData.rvmowner_id)) {
        errors.rvmowner_id = true;
        errorMessageTab.push("Le gestionnaire de RVM n'est pas valide");
    } else {
        errors.rvmowner_id = false;
    }

    if (!isLongText64Characters(formData.address_1)) {
        errors.address_1 = true;
        errorMessageTab.push("L'adresse doit faire moins de 64 caractères");
    } else {
        errors.address_1 = false;
    }
    if (formData.address_2 && !isLongText64Characters(formData.address_2)) {
        errors.address_2 = true;
        errorMessageTab.push("L'adresse doit faire moins de 64 caractères");
    } else {
        errors.address_2 = false;
    }
    if (!isCodePostal(formData.zipcode)) {
        errors.zipcode = true;
        errorMessageTab.push('La code postale est invalide');
    } else {
        errors.zipcode = false;
    }
    if (!isCity(formData.city)) {
        errors.city = true;
        errorMessageTab.push('La ville doit être composée de lettres et faire moins de 64 caractères');
    } else {
        errors.city = false;
    }
    if (!isShortText25Characters(formData.contact_name)) {
        errors.contact_name = true;
        errorMessageTab.push('Le nom du contact doit faire moins de 25 caractères');
    } else {
        errors.contact_name = false;
    }
    if (!isTelephone(formData.contact_phone)) {
        errors.contact_phone = true;
        errorMessageTab.push("Le numéro de téléphone n'est pas valide");
    } else {
        errors.contact_phone = false;
    }
    if (!isEmail(formData.contact_email)) {
        errors.contact_email = true;
        errorMessageTab.push("L'email n'est pas valide");
    } else {
        errors.contact_email = false;
    }

    setFormErrors({ ...formErrors, ...errors });
    setErrorMessage(errorMessageTab);
    if (errorMessageTab.length === 0) {
        return false;
    } else {
        setErrorMessage(errorMessageTab);
        return true;
    }
};

// POUR ADD COLLECTOR

export const checkCollectorFormErrors = (formData, formErrors, setFormErrors, setErrorMessage) => {
    const errors = {};
    const errorMessageTab = [];

    if (!isNumber(formData.rvmowner_id)) {
        errors.rvmowner_id = true;
        errorMessageTab.push('Le rvmowner est invalide');
    } else {
        errors.rvmowner_id = false;
    }

    if (!isNumber(formData.store_id)) {
        errors.store_id = true;
        errorMessageTab.push('Le magasin est invalide');
    } else {
        errors.store_id = false;
    }
    if (!isNumber(formData.type)) {
        errors.type = true;
        errorMessageTab.push("Le type de collecteur n'est pas valide");
    } else {
        errors.type = false;
    }
    if (!isNumber(formData.capacity)) {
        errors.capacity = true;
        errorMessageTab.push('La capacité doit être un nombre');
    } else {
        errors.capacity = false;
    }
    if (!isDeviceId(formData.device_id)) {
        errors.device_id = true;
        errorMessageTab.push('Le device Id doit faire moins que 15 caractères');
    } else {
        errors.device_id = false;
    }
    if (!isShortText25Characters(formData.supplier_name)) {
        errors.supplier_name = true;
        errorMessageTab.push('Le nom du fabricant doit faire moins de 25 caractères');
    } else {
        errors.supplier_name = false;
    }
    if (!isShortText25Characters(formData.supplier_model)) {
        errors.supplier_model = true;
        errorMessageTab.push('Le nom du modèle doit faire moins de 25 caractères');
    } else {
        errors.supplier_model = false;
    }

    setFormErrors({ ...formErrors, ...errors });
    setErrorMessage(errorMessageTab);
    if (errorMessageTab.length === 0) {
        return false;
    } else {
        setErrorMessage(errorMessageTab);
        return true;
    }
};

export const getRegionsName = (regionCode) => {
    switch (regionCode) {
        case 'FR-ARA':
            return 'Auvergne-Rhône-Alpes';
        case 'FR-BFC':
            return 'Bourgogne-Franche-Comté';
        case 'FR-BRE':
            return 'Bretagne';
        case 'FR-CVL':
            return 'Centre-Val de Loire';
        case 'FR-COR':
            return 'Corse';
        case 'FR-GES':
            return 'Grand Est';
        case 'FR-HDF':
            return 'Hauts-de-France';
        case 'FR-IDF':
            return 'Île-de-France';
        case 'FR-NOR':
            return 'Normandie';
        case 'FR-NAQ':
            return 'Nouvelle-Aquitaine';
        case 'FR-OCC':
            return 'Occitanie';
        case 'FR-PDL':
            return 'Pays de la Loire';
        case 'FR-PAC':
            return "Provence-Alpes-Côte d'Azur";
        case 'FR-GP':
            return 'Guadeloupe';
        case 'FR-MQ':
            return 'Martinique';
        case 'FR-GF':
            return 'Guyane';
        case 'FR-RE':
            return 'La Réunion';
        case 'FR-YT':
            return 'Mayotte';
        default:
            return 'Région inconnue';
    }
};

export const regionTab = [
    'FR-ARA',
    'FR-BFC',
    'FR-BRE',
    'FR-CVL',
    'FR-COR',
    'FR-GES',
    'FR-HDF',
    'FR-IDF',
    'FR-NOR',
    'FR-NAQ',
    'FR-OCC',
    'FR-PDL',
    'FR-PAC',
    'FR-GP',
    'FR-MQ',
    'FR-GF',
    'FR-RE',
    'FR-YT',
];

export const transformString = (input) => {
    const words = input.split('_');
    const capitalizedFirstWord = words[0].charAt(0).toUpperCase() + words[0].slice(1);
    const restOfWords = words.slice(1).map((word) => word.toLowerCase());
    return [capitalizedFirstWord, ...restOfWords].join(' ');
};
