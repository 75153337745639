// COLLECTOR API
export const getCollectorCheck = (data) => {
    const name = 'getCollector : ';
    for (let i = 0; i < data.length; i++) {
        if (!data[i].store.id) {
            return name + 'No store id found';
        } else if (!data[i].store.depot.id) {
            return name + 'No depot id found';
        } else if (!data[i].store.depot.entity.id) {
            return name + 'No sorter id found';
        } else {
            return true;
        }
    }
};

export const getCollectorsByDatesCheck = (data) => {
    const name = 'getCollectorByDates : ';

    for (let i = 0; i < data.length; i++) {
        if (!data[i].store.id) {
            return name + 'No store id found';
        } else if (!data[i].store.depot.id) {
            return name + 'No depot id found';
        } else if (!data[i].store.depot.entity.id) {
            return name + 'No sorter id found';
        } else {
            return true;
        }
    }
};

export const getCollectorByIdcheck = (data) => {
    const name = 'getCollectorByDates : ';

    for (let i = 0; i < data.length; i++) {
        if (!data[i].store.id) {
            return name + 'No store id found';
        } else if (!data[i].store.depot.id) {
            return name + 'No depot id found';
        } else if (!data[i].store.depot.entity.id) {
            return name + 'No sorter id found';
        } else {
            return true;
        }
    }
};

export const getCollectorsByRvmownerCheck = (data) => {
    const name = 'getCollectorsByRvmowner : ';

    for (let i = 0; i < data.length; i++) {
        if (!data[i].store.id) {
            return name + 'No store id found';
        } else if (!data[i].store.depot.id) {
            return name + 'No depot id found';
        } else if (!data[i].store.depot.entity.id) {
            return name + 'No sorter id found';
        } else {
            return true;
        }
    }
};

export const getCollectorsByDepotIdCheck = (data) => {
    const name = 'getCollectorsByDepotId : ';

    for (let i = 0; i < data.length; i++) {
        if (!data[i].store.id) {
            return name + 'No store id found';
        } else if (!data[i].store.depot.id) {
            return name + 'No depot id found';
        } else {
            return true;
        }
    }
};

export const getCollectorsLastCollectorEventsStatusCheck = (data) => {
    const name = 'getCollectorsLastCollectorEventsStatus : ';
    for (let i = 0; i < data.length; i++) {
        if (!data[i].store.id) {
            return name + 'No store id found';
        } else if (!data[i].store.depot.id) {
            return name + 'No depot id found';
        } else if (!data[i].store.depot.entity.id) {
            return name + 'No sorter id found';
        } else if (!data[i].collectorEvents[0].id) {
            return name + 'No collector event id found';
        } else if (!data[i].collectorEvents[0].suivi.id) {
            return name + 'No suivi id found';
        } else {
            return true;
        }
    }
};
