import { useState, useEffect, useRef } from 'react';
import { getSorterById, getSorterEventsByOperator, sorterPickUpAccept } from '../../../API/SorterApi.js';
import { getIndustrialById } from '../../../API/IndustrialApi.js';
import { _defineCollecteType, _defineStoreEventType, _defineBoxesStoreState } from '../../../Utils.js';
import * as DateFns from 'date-fns';
import * as Constant from '../../../Constants.js';
import { Jwt } from '../../../Jwt.js';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
import { getStoreEventDetailsByOperatorId, storePickUpAccept } from '../../../API/StoreApi.js';
export default function CollecteList() {
    const navigate = useNavigate();
    const userContext = new Jwt();
    const today = DateFns.format(new Date(), 'yyyy-MM-dd');

    const [collecteData, setCollecteData] = useState([]);
    const [acceptDate, setAcceptDate] = useState(today);
    const [acceptHour, setAcceptHour] = useState('00:00');

    const [allEvents, setAllEvents] = useState([]);

    const dialogRef = useRef(null);

    useEffect(() => {
        const fetchAllEvents = async () => {
            const { gearApi } = await import('../../../gearApi.js');

            const fetchStoreEventsRowData = (await getStoreEventDetailsByOperatorId(userContext.entityId)) || [];

            const groupByStore = fetchStoreEventsRowData.reduce((acc, obj) => {
                const key = `${obj.storeEvent.storeId}-${obj.storeEvent.type}`;

                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(obj);
                return acc;
            }, {});

            const storeEventsRowData = Object.keys(groupByStore).map((key) => ({
                time: groupByStore[key][0].storeEvent.time,
                status: groupByStore[key][0].storeEvent.status,
                collecte_type: Constant.COLLECTETYPE_STORE,
                item: groupByStore[key],
                suiviId: groupByStore[key][0].suiviId,
                departure: groupByStore[key][0].storeEvent.store.depot,
            }));

            for (let i = 0; i < storeEventsRowData.length; i++) {
                const sorterInfo = await getSorterById(storeEventsRowData[i].item[0].storeEvent.sorterId);
                if (storeEventsRowData[i].item[0].storeEvent.type === Constant.STOREEVENT_TYPE_COLLECTE) {
                    storeEventsRowData[i].arrival = sorterInfo[0];
                } else {
                    storeEventsRowData[i].arrival = storeEventsRowData[i].departure;
                    storeEventsRowData[i].departure = sorterInfo[0];
                }
            }

            const sorterEventsRowData = (await getSorterEventsByOperator(userContext.entityId)) || [];
            for (let i = 0; i < sorterEventsRowData.length; i++) {
                const event = sorterEventsRowData[i];
                const sorterInfo = await getSorterById(event.sorterId);
                const washerInfo = await gearApi.washer.getWasherById({ washerId: event.washerId });
                sorterEventsRowData[i] = {
                    ...event,
                    collecte_type: Constant.COLLECTETYPE_SORTER,
                    departure: sorterInfo[0],
                    arrival: washerInfo,
                };
            }
            const washerEventsRowData = (await gearApi.washer.getEventsOfWashers({ operatorId: userContext.entityId })) || [];
            for (let i = 0; i < washerEventsRowData.length; i++) {
                const event = washerEventsRowData[i];
                const washerInfo = await gearApi.washer.getWasherById({ washerId: event.washerId });
                const industrialInfo = await getIndustrialById(event.industrialId);
                washerEventsRowData[i] = {
                    ...event,
                    collecte_type: Constant.COLLECTETYPE_WASHER,
                    departure: washerInfo,
                    arrival: industrialInfo[0],
                };
            }

            const tab = [...storeEventsRowData, ...sorterEventsRowData, ...washerEventsRowData];
            const finalData = filterData(tab);

            setAllEvents(finalData);
        };

        fetchAllEvents();
    }, [userContext.entityId, navigate]);

    const filterData = (data) => {
        const filteredData = [];
        const groupedData = {};
        data.forEach((item) => {
            if (!groupedData[item.suiviId]) {
                groupedData[item.suiviId] = [];
            }
            groupedData[item.suiviId].push(item);
        });

        Object.keys(groupedData).forEach((suiviId) => {
            const itemsWithWrongStatus = groupedData[suiviId].some(
                (item) =>
                    item.status === Constant.STOREEVENT_ASKED ||
                    item.status === Constant.EVENT_ACCEPTED ||
                    item.status === Constant.EVENT_DONE ||
                    item.status === Constant.EVENT_RECEIVED,
            );
            if (!itemsWithWrongStatus) {
                filteredData.push(...groupedData[suiviId]);
            }
        });

        return filteredData;
    };

    const handleModalOpen = async (item) => {
        setCollecteData(item);

        if (dialogRef.current) {
            dialogRef.current.showModal();
        }
    };
    const handleModalClose = () => {
        setCollecteData([]);
        setAcceptDate(today);
        setAcceptHour('00:00');
        if (dialogRef.current) {
            dialogRef.current.close();
        }
    };

    const sendPickUpAccept = async () => {
        const dateTimeString = `${acceptDate} ${acceptHour}:00`;

        try {
            // First in case we use GearApi already
            if (collecteData.collecte_type === Constant.COLLECTETYPE_WASHER) {
                const { gearApi } = await import('../../../gearApi.js');
                await gearApi.washer
                    .putStatusToSuiviofEventsForWashers({
                        status: Constant.EVENT_ACCEPTED,
                        suiviId: collecteData.suiviId,
                        washerId: collecteData.washerId,
                        requestBody: {
                            industrialId: collecteData.industrialId,
                            operatorId: collecteData.operatorId,
                            time: DateFns.endOfDay(dateTimeString),
                        },
                    })
                    .then(function () {
                        alert('Date de collecte prise en compte');
                        handleModalClose();
                        window.location.reload();
                    })
                    .catch(function (err) {
                        console.debug(err);
                        Sentry.captureException(err);
                        alert('Une erreur est survenue');
                    });
            } else {
                const pickUpAcceptFunction = (type) => {
                    switch (type) {
                        case Constant.COLLECTETYPE_STORE:
                            return storePickUpAccept(collecteData, dateTimeString);
                        case Constant.COLLECTETYPE_SORTER:
                            return sorterPickUpAccept(collecteData, dateTimeString);
                        case Constant.COLLECTETYPE_WASHER:
                            break; // Special treatment for the new GearApi
                        default:
                            return 'Type de collecte non géré';
                    }
                };

                const data = await pickUpAcceptFunction(collecteData.collecte_type);
                if (data.message === 'ok') {
                    alert('Date de collecte prise en compte');
                    handleModalClose();
                    window.location.reload();
                } else {
                    alert('Une erreur est survenue');
                }
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <main>
            <section className="big-section">
                <h2>Collectes à valider</h2>
                <div className="tab-item">
                    <div
                        className="pre-filled-fields-1-several-item"
                        style={{ fontWeight: '600', width: '100%' }}>
                        <p>Type de collecte</p>
                        <p>Départ</p>
                        <p>Arrivée</p>
                        <p>Date estimée de collecte</p>
                    </div>
                    {allEvents.length ? (
                        allEvents.map((item, index) => {
                            return (
                                <div
                                    style={{ display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'space-between', cursor: 'pointer' }}
                                    key={index}>
                                    <div
                                        style={{ width: '100%' }}
                                        onClick={() => {
                                            handleModalOpen(item);
                                        }}
                                        className="pre-filled-fields-2-several-item">
                                        <p>
                                            {item.collecte_type === Constant.COLLECTETYPE_STORE
                                                ? `${_defineCollecteType(item.collecte_type)} - ${_defineStoreEventType(item.item[0].storeEvent.type)} `
                                                : _defineCollecteType(item.collecte_type)}
                                        </p>
                                        <p>{item.departure.name}</p>
                                        <p>{item.arrival.name}</p>
                                        <p>{DateFns.format(item.time, 'yyyy-MM-dd')}</p>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <div className="no-data-graph ">Aucune collecte à valider.</div>
                    )}
                </div>
            </section>
            <dialog
                ref={dialogRef}
                className="dialog-collector">
                <div className="title">
                    <p>Information collecte</p>
                    <button
                        className="button-negatif"
                        onClick={handleModalClose}>
                        X
                    </button>
                </div>
                {collecteData && collecteData.departure && collecteData.arrival ? (
                    <>
                        <div className="collector-infos">
                            <div className="depart-arrival-container">
                                <div>
                                    <h4>Départ</h4>
                                    <p>{collecteData.departure.company_name}</p>
                                    <p>{collecteData.departure.address_1}</p>
                                    <p>{collecteData.departure.address_2}</p>
                                    <p>
                                        {collecteData.departure.zipcode},{collecteData && collecteData.departure.city}
                                    </p>
                                </div>

                                <div className="arrival">
                                    <h4>Arrivée</h4>
                                    <p>{collecteData.arrival.company_name}</p>
                                    <p>{collecteData.arrival.address_1}</p>
                                    <p>{collecteData.arrival.address_2}</p>
                                    <p>
                                        {collecteData.arrival.zipcode},{collecteData.arrival.city}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="collector-infos">
                            {collecteData.collecte_type === Constant.COLLECTETYPE_STORE &&
                                collecteData.item.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                padding: '0.5rem 0',
                                            }}>
                                            <p>
                                                {item.container.name} - {_defineBoxesStoreState(item.empty)}
                                            </p>
                                            <p>x{item.qty}</p>
                                        </div>
                                    );
                                })}
                        </div>
                        <div
                            className="collector-date"
                            style={{ marginTop: '1rem' }}>
                            <label>A collecter avant le :</label>
                            <p>{DateFns.format(collecteData.time, 'yyyy-MM-dd')}</p>
                        </div>
                        <div
                            className="collector-date"
                            style={{ borderTop: '0px' }}>
                            <label style={{ width: '50%' }}>Sélectionner une date de collecte :</label>
                            <input
                                type="date"
                                min={today}
                                defaultValue={DateFns.format(collecteData.time, 'yyyy-MM-dd')}
                                onChange={(e) => {
                                    setAcceptDate(e.target.value);
                                }}
                            />
                        </div>
                        {collecteData.collecte_type === Constant.COLLECTETYPE_STORE ? (
                            <div
                                className="collector-date"
                                style={{ borderTop: '0px' }}>
                                <label style={{ width: '50%' }}>Selectionner un horaire :</label>
                                <div
                                    className="radio-group"
                                    style={{ display: 'flex', width: '50%' }}>
                                    <label
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            flex: 1,
                                            alignItems: 'center',
                                            fontWeight: '400',
                                        }}>
                                        <input
                                            style={{ width: '1rem', marginBottom: '0.5rem' }}
                                            type="radio"
                                            value="09:00"
                                            checked={acceptHour === '09:00'}
                                            onChange={(e) => {
                                                setAcceptHour(e.target.value);
                                            }}
                                        />
                                        <p style={{ width: '100%', textAlign: 'center', fontSize: '0.9rem' }}> 9h - 12h</p>
                                    </label>
                                    <label
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            flex: 1,
                                            alignItems: 'center',
                                            fontWeight: '400',
                                        }}>
                                        <input
                                            style={{ width: '1rem', marginBottom: '0.5rem' }}
                                            type="radio"
                                            value="12:00"
                                            checked={acceptHour === '12:00'}
                                            onChange={(e) => {
                                                setAcceptHour(e.target.value);
                                            }}
                                        />
                                        <p style={{ width: '100%', textAlign: 'center', fontSize: '0.9rem' }}> 12h - 15h</p>
                                    </label>
                                    <label
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            flex: 1,
                                            alignItems: 'center',
                                            fontWeight: '400',
                                        }}>
                                        <input
                                            style={{ width: '1rem', marginBottom: '0.5rem' }}
                                            type="radio"
                                            value="15:00"
                                            checked={acceptHour === '15:00'}
                                            onChange={(e) => {
                                                setAcceptHour(e.target.value);
                                            }}
                                        />
                                        <p style={{ width: '100%', textAlign: 'center', fontSize: '0.9rem' }}> 15h - 18h</p>
                                    </label>
                                </div>
                            </div>
                        ) : null}
                    </>
                ) : null}

                <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '2rem' }}>
                    <button
                        className="button-negatif"
                        onClick={handleModalClose}>
                        ANNULER
                    </button>
                    <button
                        onClick={sendPickUpAccept}
                        className="button-actif">
                        VALIDER
                    </button>
                </div>
            </dialog>
        </main>
    );
}
