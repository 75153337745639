import { useState, useEffect } from 'react';
import {
    getCollectors,
    // createPickUpDemand,
    // getEventsByCollectorId,
    // getCollectorById,
    // getCollectorEventsByOperatorIdAndDate,
} from '../../../../../API/CollectorApi';
// import { getDepotByIdCheck } from '../../../../../API/CheckApi/CheckDepotApi';
// import { getCollectorCheck, getCollectorByIdcheck } from '../../../../../API/CheckApi/CheckCollectorApi';
import { getCollectorCheck } from '../../../../../API/CheckApi/CheckCollectorApi';
// import { getOperators } from '../../../../../API/OperatorApi';
// import { getDepotById } from '../../../../../API/DepotApi';
import { _defineCollectorType, _defineCollecteStatus } from '../../../../../Utils';
import { format } from 'date-fns';
// import * as Constant from '../../../../../Constants';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
import { getEventDetailsByStoreIdInProgress } from '../../../../../API/StoreApi';
import * as Constants from '../../../../../Constants';

export default function CollectorPickup() {
    const navigate = useNavigate();

    // const [AllCollectorList, setAllCollectorList] = useState([]);
    // const [infosModal, setInfosModal] = useState();
    const [data, setData] = useState([]);
    // const dateActuelle = new Date();
    // const dateMax = addDays(dateActuelle, 5);
    // const dateMaxFormatted = format(dateMax, 'yyyy-MM-dd');
    // const [pickUpRequestTime, setPickUpRequestTime] = useState(dateMaxFormatted);
    // const [depotAddress, setDepotAddress] = useState([]);
    // const [collectorAssociated, setCollectorAssociated] = useState([]);
    // const [selectedOptions, setSelectedOptions] = useState([]);
    // const [selectedOptionName, setSelectedOptionName] = useState([]);
    // const [collecteInfos, setCollecteInfos] = useState([]);
    // const [operatorList, setOperatorList] = useState([]);
    // const [operatorId, setOperatorId] = useState('');

    // const dialogRef = useRef(null);

    useEffect(() => {
        const fetchAllCollectors = async () => {
            try {
                const result = await getCollectors();
                const checkResultCollector = getCollectorCheck(result);
                if (result.length && checkResultCollector !== true) {
                    console.error(checkResultCollector);
                    Sentry.captureException(checkResultCollector);
                    navigate('/error');
                    return;
                }
                console.log('result', result);

                let tab = [];
                for (let i = 0; i < result.length; i++) {
                    const collecteData = await getEventDetailsByStoreIdInProgress(result[i].store.id);
                    if (collecteData.length) {
                        tab.push({ ...result[i], collecte: collecteData[0] });
                    } else if (result[i].filling_rate >= 80) {
                        tab.push({ ...result[i], collecte: [] });
                    }
                }

                setData(tab);

                // setAllCollectorList(result);
            } catch (error) {
                console.error('Erreur lors de la récupération des collectors', error);
            }
        };

        fetchAllCollectors();
    }, [navigate]);

    // const handleModalOpen = async (item) => {
    //     const data = await getDepotById(item.depot_id);
    //     const dataCheck = getDepotByIdCheck(data);
    //     if (data.length && dataCheck !== true) {
    //         console.error(dataCheck);
    //         Sentry.captureException(dataCheck);
    //         navigate('/error');
    //         return;
    //     }
    //     setDepotAddress(data[0]);
    //     const operators = await getOperators();
    //     setOperatorList(operators);
    //     if (item.collecte && item.collecte.request) {
    //         const collecteInfo = await getCollectorEventsByOperatorIdAndDate(item.collecte.request.operatorId, item.collecte.request.createdAt);
    //         let collectetab = [];
    //         for (let i = 0; i < collecteInfo.length; i++) {
    //             const result = await getCollectorById(collecteInfo[i].collectorId);
    //             const resultCheck = getCollectorByIdcheck(result);
    //             if (result.length && resultCheck !== true) {
    //                 console.error(resultCheck);
    //                 Sentry.captureException(resultCheck);
    //                 navigate('/error');
    //                 return;
    //             }
    //             collectetab.push(result[0]);
    //         }
    //         setCollecteInfos(collectetab);
    //     }
    //     setInfosModal(item);
    //     const result = AllCollectorList.filter((objet) => objet.depot_id === item.depot_id);

    //     setCollectorAssociated(result);
    //     let tab = [];
    //     if (result.length) {
    //         for (let i = 0; i < result.length; i++) {
    //             if (selectedOptions && !selectedOptions.includes(parseInt(result[i].id))) {
    //                 tab.push(parseInt(result[i].id));
    //             }
    //         }
    //         setSelectedOptions(...selectedOptions, tab);
    //     }

    //     if (dialogRef.current) {
    //         dialogRef.current.showModal();
    //     }
    // };

    // const handleModalClose = () => {
    //     setSelectedOptionName([]);
    //     setSelectedOptions([]);
    //     if (dialogRef.current) {
    //         dialogRef.current.close();
    //     }
    // };
    // const handlePickUpRequestChange = (event) => {
    //     setPickUpRequestTime(event.target.value);
    // };
    // const sendPickUpRequest = async (collectorIds, sorterId) => {
    //     const operatorIdNum = parseInt(operatorId);
    //     if (!operatorIdNum) {
    //         alert('veuillez selectionner un operateur de  collecte');
    //     } else {
    //         createPickUpDemand(collectorIds, sorterId, operatorIdNum, pickUpRequestTime)
    //             .then((data) => {
    //                 if (data.message === 'ok') {
    //                     alert('Le demande de collecte a été faite avec succès');
    //                     handleModalClose();
    //                     window.location.reload();
    //                 } else {
    //                     alert('Une rreur est survenue');
    //                 }
    //             })
    //             .catch((err) => {
    //                 console.log(err);
    //             });
    //     }
    // };

    // useEffect(() => {
    //     const fetchData = async (id) => {
    //         try {
    //             const dateToday = new Date();
    //             const response = await getEventsByCollectorId(id);
    //             const lastPickUpDate = response.filter((objet) => objet.status === 3);
    //             const objetsStatus0 = response.filter((objet) => objet.status === 0);
    //             const dernierStatus0 = objetsStatus0[0];
    //             const objetsStatus1AvecSuiviIDCorrespondant = response.filter((objet) => objet.status === 1 && objet.suiviId === dernierStatus0.suiviId);
    //             const dernierStatus1AvecSuiviIDCorrespondant = objetsStatus1AvecSuiviIDCorrespondant[0];
    //             const objetsStatus3AvecMemeSuiviID = response.filter((objet) => objet.status === 3 && objet.suiviId === dernierStatus0.suiviId);
    //             const premierStatus3AvecMemeSuiviID = objetsStatus3AvecMemeSuiviID[0];
    //             const lastPickUpDateToCalcul = lastPickUpDate[0] ? lastPickUpDate[0].time : null;
    //             const differenceInDaysValue = differenceInDays(dateToday, lastPickUpDateToCalcul);

    //             const resultat = {
    //                 lastPickUpDate: lastPickUpDate[0] ? lastPickUpDate[0].time : null,
    //                 timeSinceLastCollecte: differenceInDaysValue,
    //                 request: dernierStatus0,
    //                 accept: dernierStatus1AvecSuiviIDCorrespondant,
    //                 done: premierStatus3AvecMemeSuiviID,
    //             };
    //             return resultat;
    //         } catch (error) {
    //             console.error("Une erreur s'est produite : ", error);
    //         }
    //     };
    //     const fetchDataForItems = async () => {
    //         const newData = [];
    //         for (const item of AllCollectorList) {
    //             const collecte = await fetchData(item.id);
    //             newData.push({ ...item, collecte });
    //         }
    //         const newDataToFilter = newData.filter((item) => item.filling_rate >= 80 || item.collecte.timeSinceLastCollecte > 21);
    //         setData(newDataToFilter);
    //     };

    //     fetchDataForItems();
    // }, [AllCollectorList]);

    // useEffect(() => {
    //     const fetchData = async (id) => {

    //         const
    //     };
    // }, [AllCollectorList]);

    // const handleOptionChange = async (e) => {
    //     if (e.target.value !== '') {
    //         if (selectedOptions && !selectedOptions.includes(parseInt(e.target.value))) {
    //             setSelectedOptions([...selectedOptions, parseInt(e.target.value)]);
    //             const result = await getCollectorById(e.target.value);
    //             const resultCheck = getCollectorByIdcheck(result);
    //             if (result.length && resultCheck !== true) {
    //                 console.error(resultCheck);
    //                 Sentry.captureException(resultCheck);
    //                 navigate('/error');
    //                 return;
    //             }

    //             setSelectedOptionName([...selectedOptionName, result[0]]);
    //         } else {
    //             console.log('La valeur existe déjà dans tab.');
    //         }
    //     }
    // };

    // const supprimerLigne = (item) => {
    //     const newData = selectedOptionName.filter((elem) => elem.id !== item.id);
    //     setSelectedOptionName(newData);
    //     const updatedItems = selectedOptions.filter((elem) => elem !== item.id);
    //     setSelectedOptions(updatedItems);
    // };

    return (
        <main>
            <section className="big-section">
                <h2>Collectes en cours - à programmer</h2>
                <div className="tab-item">
                    <div
                        className="pre-filled-fields-1-several-item"
                        style={{ fontWeight: '600', width: '100%' }}>
                        <p>Nom</p>
                        <p>Type</p>
                        <p>Taux</p>
                        <p>Dernière collecte</p>
                        <p>Centre de tri</p>
                        <p>Prochaine collecte estimée</p>
                        <p>Statut</p>
                    </div>

                    {/* {data && data.length ? (
                        data.map((item, index) => {
                            return (
                                <div
                                  >
                                    <div
                                        style={{ width: '100%' }}
                                        onClick={() => {
                                            handleModalOpen(item);
                                        }}
                                        className="pre-filled-fields-2-several-item">
                                        <p>
                                            {item.store_name}-{item.id}
                                        </p>
                                        <p>{_defineCollectorType(item.type)}</p>
                                        <p>xx</p>
                                        <p style={{ color: item.filling_rate >= 80 && Constant.$rouge }}>{item.filling_rate}</p>
                                        <p style={{ color: item.collecte && item.collecte.timeSinceLastCollecte > 21 && Constant.$rouge }}>
                                            {item.collecte && item.collecte.lastPickUpDate
                                                ? format(item.collecte.lastPickUpDate, 'yyyy-MM-dd HH:mm:ss')
                                                : 'N.D'}
                                        </p>
                                        <p>{item.sorter_name}</p>
                                        <p>
                                            {(item.collecte &&
                                                !item.collecte.done &&
                                                item.collecte.request &&
                                                item.collecte.request.time &&
                                                format(item.collecte.request.time, 'yyyy-MM-dd HH:mm:ss')) ||
                                                'N.D'}
                                        </p>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <div className="no-data-graph ">Aucune collecte à programmer.</div>
                    )} */}

                    {data.length
                        ? data.map((item, index) => {
                              return (
                                  <div
                                      style={{ display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'space-between', cursor: 'pointer' }}
                                      key={index}>
                                      <div
                                          style={{ width: '100%' }}
                                          className="pre-filled-fields-2-several-item">
                                          <p>{item.store_name}</p>
                                          <p>{_defineCollectorType(item.type)}</p>
                                          <p>{item.filling_rate}</p>
                                          <p>/</p>
                                          <p>{item.sorter_name}</p>
                                          <p>{item.collecte.storeEvent && format(item.collecte.storeEvent.time, 'yyyy-MM-dd HH:mm:ss')}</p>
                                          <p
                                              style={{
                                                  backgroundColor: item.collecte.storeEvent && _defineCollecteStatus(item.collecte.storeEvent.status).color,
                                                  color: Constants.$blanc,
                                                  borderTopRightRadius: '0.62rem',
                                                  borderBottomRightRadius: '0.62rem',
                                              }}>
                                              {item.collecte.storeEvent && _defineCollecteStatus(item.collecte.storeEvent.status).name}
                                          </p>
                                      </div>
                                  </div>
                              );
                          })
                        : null}
                </div>
            </section>
            {/* <dialog
                ref={dialogRef}
                className="dialog-collector">
                <div className="title">
                    <p>
                        {infosModal && infosModal.collecte && infosModal.collecte.request && !infosModal.collecte.accept
                            ? 'Prochaine collecte'
                            : 'Programmer une collecte'}
                    </p>
                    <button
                        className="button-negatif"
                        onClick={handleModalClose}>
                        X
                    </button>
                </div>
                <div className="collector-infos">
                    <p>
                        Nom collecteur : <span>{infosModal && `${infosModal.store_name}-${infosModal.id}`}</span>
                    </p>
                    <p>
                        Type collecteur : <span>{infosModal && _defineCollectorType(infosModal.type)}</span>
                    </p>
                    <p>
                        Nom du magasin associé : <span>{infosModal && infosModal.store_name}</span>
                    </p>
                    <p>
                        Adresse :
                        <span>{depotAddress && `${depotAddress.address_1},${depotAddress.address_2} ${depotAddress.city}, ${depotAddress.zipcode} `}</span>
                    </p>
                    <p>
                        Taux de remplissage : <span>{infosModal && infosModal.filling_rate}%</span>
                    </p>
                </div>
                {infosModal && infosModal.collecte && infosModal.collecte.request && !infosModal.collecte.accept ? (
                    <div className="collector-associated">
                        <h5>Autres collecteurs concernés par cette collecte :</h5>
                        {collecteInfos.length ? (
                            collecteInfos.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        style={{ display: item.id === infosModal.id && 'none' }}>
                                        <p>
                                            Nom collecteur : <span>{`${item.store_name} - ${item.id}`}</span>
                                        </p>
                                    </div>
                                );
                            })
                        ) : (
                            <span>Aucun.</span>
                        )}
                    </div>
                ) : (
                    <div className="collector-associated">
                        <h5>Collecteurs du même dépot :</h5>
                        {collectorAssociated.length > 1 ? (
                            collectorAssociated.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        style={{ display: item.id === infosModal.id && 'none' }}>
                                        <p>
                                            Nom collecteur : <span>{`${item.store_name} - ${item.id}`}</span>
                                        </p>
                                    </div>
                                );
                            })
                        ) : (
                            <span> Aucun.</span>
                        )}
                    </div>
                )}

                {infosModal && infosModal.collecte && infosModal.collecte.request && !infosModal.collecte.accept ? null : (
                    <div className="collector-added">
                        <h5>Ajouter des collecteurs à cette collecte :</h5>
                        <select
                            onChange={handleOptionChange}
                            value={''}>
                            <option value="">Choisir</option>
                            {AllCollectorList.length &&
                                AllCollectorList.filter((collecteur) => selectedOptions.length && !selectedOptions.includes(parseInt(collecteur.id))).map(
                                    (collecteur, index) => (
                                        <option
                                            key={index}
                                            value={collecteur.id}>
                                            {`${collecteur.store_name} - ${collecteur.id}`}
                                        </option>
                                    ),
                                )}
                        </select>
                        <div className="collector-added-response">
                            {selectedOptionName.length
                                ? selectedOptionName.map((item, index) => {
                                      return (
                                          <div key={index}>
                                              <p>
                                                  Nom collecteur : <span>{`${item.store_name} - ${item.id}`}</span>
                                              </p>
                                              <button onClick={() => supprimerLigne(item)}>x</button>
                                          </div>
                                      );
                                  })
                                : null}
                        </div>
                    </div>
                )}
                <div className="collector-date">
                    <label> Operateur de collecte :</label>
                    {infosModal && infosModal.collecte && infosModal.collecte.request && !infosModal.collecte.accept ? (
                        <select disabled>
                            {operatorList.length &&
                                operatorList
                                    .filter((operator) => operator.id === infosModal.collecte.request.operatorId)
                                    .map((operator, index) => (
                                        <option
                                            key={index}
                                            value={operator.id}>
                                            {operator.name}
                                        </option>
                                    ))}
                        </select>
                    ) : (
                        <select
                            onChange={(e) => {
                                setOperatorId(e.target.value);
                            }}>
                            <option value="">Choisir</option>
                            {operatorList.length &&
                                operatorList.map((operator, index) => (
                                    <option
                                        key={index}
                                        value={operator.id}>
                                        {operator.name}
                                    </option>
                                ))}
                        </select>
                    )}
                </div>
                <div
                    className="collector-date"
                    style={{ border: '0px' }}>
                    <label>A collecter avant le :</label>
                    {infosModal && infosModal.collecte && infosModal.collecte.request && !infosModal.collecte.accept ? (
                        format(infosModal.collecte.request.time, 'yyyy-MM-dd HH:mm:ss')
                    ) : (
                        <input
                            min={format(dateActuelle, 'yyyy-MM-dd')}
                            type="date"
                            defaultValue={dateMaxFormatted}
                            onChange={handlePickUpRequestChange}
                        />
                    )}
                </div>
                {infosModal && infosModal.collecte && infosModal.collecte.request && !infosModal.collecte.accept ? null : (
                    <div className="collector-validate">
                        <button
                            className="button-actif"
                            onClick={() => {
                                sendPickUpRequest(selectedOptions, infosModal.sorter_id);
                            }}>
                            VALIDER LA PROGRAMMATION
                        </button>
                    </div>
                )}
            </dialog> */}
        </main>
    );
}
